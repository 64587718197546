import React from "react";

const CargoShipSvgIcon = ({color, width, height, fillShip, fillBoxes}: {color?: string, width?: string, height?: string, fillShip?: string, fillBoxes?: string}):JSX.Element => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={0} height={0} viewBox="0 0 1256.000000 553.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
                height: height || "553.000000pt",
                width: width || "1256.000000pt"
            }}
        >

            <g transform="translate(0.000000,553.000000) scale(0.100000,-0.100000)"
                fill={color || "#000000"} stroke="none">
                <path fill={fillShip || "#000000"} d="M690 5241 c-8 -5 -28 -13 -45 -18 -26 -8 -29 -13 -26 -38 2 -17 6
-462 10 -990 l6 -960 67 -3 c120 -5 108 -93 108 763 0 409 3 750 6 759 5 14
34 16 214 16 180 0 209 2 214 16 3 9 6 91 6 183 0 135 3 174 16 200 26 51 13
56 -164 59 -91 2 -162 8 -170 14 -9 8 -16 8 -24 0 -15 -15 -154 -16 -163 -2
-7 12 -34 12 -55 1z"/>
                <path fill={fillBoxes || "#000000"} d="M4194 4805 c-3 -6 -3 -143 -2 -305 l3 -295 705 -3 c388 -1 715 0 728
3 l22 5 -2 298 -3 297 -724 5 c-483 3 -725 2 -727 -5z"/>
                <path fill={fillBoxes || "#000000"} d="M5722 4508 l3 -303 708 -3 c480 -1 714 1 728 8 19 10 19 22 17 303
l-3 292 -728 3 -727 2 2 -302z"/>
                <path fill={fillShip || "#000000"} d="M1841 4706 c-10 -12 -89 -15 -494 -15 -265 -1 -485 -5 -490 -10 -5
-5 -3 -19 5 -32 10 -19 14 -163 18 -719 l5 -695 530 -3 c447 -2 534 0 550 12
19 14 20 33 25 558 l5 543 53 6 c29 4 56 10 60 13 9 10 5 77 -5 87 -5 5 -45 9
-89 8 -43 0 -90 -1 -104 -1 -14 0 -34 1 -45 2 -11 1 -37 -1 -57 -5 -20 -4 -45
-4 -55 0 -10 5 -22 7 -28 6 -5 -2 -90 -3 -188 -4 -144 0 -180 2 -188 14 -14
22 -11 85 5 98 10 8 116 11 367 10 195 0 361 2 370 6 20 8 54 79 45 94 -5 7
-48 11 -114 11 -75 0 -115 5 -136 15 -34 18 -31 18 -45 1z m-473 -488 c12 -12
16 -116 6 -155 -5 -20 -13 -23 -53 -23 -26 0 -52 5 -59 12 -7 7 -12 41 -12 85
0 58 3 74 18 82 24 14 85 14 100 -1z m250 -85 l3 -93 -55 0 c-40 0 -55 4 -60
16 -8 22 -8 159 1 168 4 4 30 6 58 4 l50 -3 3 -92z m-500 -285 c15 -15 16
-142 2 -169 -13 -24 -66 -26 -98 -3 -19 13 -22 24 -22 90 0 68 2 75 23 84 32
13 81 12 95 -2z m252 -7 c13 -25 13 -122 0 -155 -8 -21 -16 -26 -43 -26 -18 0
-43 7 -55 16 -19 13 -22 24 -22 88 0 43 5 77 12 84 7 7 31 12 55 12 32 0 45
-5 53 -19z m248 -77 l3 -91 -38 -6 c-25 -4 -45 -2 -60 9 -21 13 -23 22 -23 93
0 43 3 81 7 85 4 4 30 6 58 4 l50 -3 3 -91z m250 7 c2 -46 -2 -89 -7 -94 -16
-16 -91 -14 -101 3 -11 17 -13 163 -3 174 4 4 30 6 58 4 l50 -3 3 -84z m-754
-287 c21 -21 22 -146 2 -163 -8 -7 -36 -11 -63 -9 l-48 3 -3 65 c-5 104 2 116
70 119 15 0 34 -6 42 -15z m250 0 c20 -19 22 -132 3 -158 -10 -13 -24 -17 -62
-14 l-50 3 -3 80 c-2 66 0 82 14 92 25 18 79 16 98 -3z m240 3 c14 -10 16 -26
14 -92 l-3 -80 -55 0 -55 0 -3 83 c-3 78 -2 83 20 92 33 13 62 12 82 -3z m250
0 c14 -10 16 -26 14 -92 l-3 -80 -55 0 -55 0 -3 78 c-2 50 1 84 9 93 14 17 69
18 93 1z"/>
                <path fill={fillBoxes || "#000000"} d="M2673 4123 c-10 -3 -13 -72 -13 -294 l0 -289 25 -16 c23 -15 87 -16
725 -11 384 3 704 8 709 12 16 9 15 577 -1 593 -9 9 -182 12 -723 11 -390 0
-716 -3 -722 -6z"/>
                <path fill={fillBoxes || "#000000"} d="M4197 4123 c-4 -3 -7 -141 -7 -305 l0 -298 728 2 727 3 3 294 c2 229
-1 296 -10 303 -16 9 -1432 11 -1441 1z"/>
                <path fill={fillBoxes || "#000000"} d="M5725 4117 c-3 -7 -4 -143 -3 -302 l3 -290 725 0 725 0 6 175 c4 96
4 230 1 297 -5 113 -6 122 -26 127 -12 3 -337 6 -724 6 -557 0 -704 -3 -707
-13z"/>
                <path fill={fillBoxes || "#000000"} d="M7253 4118 c-14 -17 -27 -136 -26 -228 1 -110 17 -358 23 -364 8 -8
1437 -9 1449 -1 7 4 11 103 11 300 0 258 -2 294 -16 299 -9 3 -334 6 -724 6
-554 0 -710 -3 -717 -12z"/>
                <path fill={fillBoxes || "#000000"} d="M2678 3449 c-17 -9 -18 -34 -18 -297 0 -158 4 -292 8 -298 4 -5 25
-21 47 -34 l40 -25 -35 -5 c-20 -4 -41 -10 -48 -14 -17 -10 -17 -577 0 -594
18 -18 1431 -17 1446 1 15 17 21 572 6 587 -6 6 -71 15 -145 19 -74 4 -111 9
-84 10 28 0 59 7 70 15 11 7 49 17 85 21 36 4 68 12 72 19 14 20 9 581 -4 594
-16 16 -1414 17 -1440 1z m789 -656 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0
53 -2 32 -4z"/>
                <path fill={fillBoxes || "#000000"} d="M4197 3453 c-12 -11 -8 -601 4 -608 14 -9 1423 -10 1438 -1 16 10 16
587 -1 604 -9 9 -183 12 -723 12 -392 0 -715 -3 -718 -7z"/>
                <path fill={fillBoxes || "#000000"} d="M5725 3447 c-3 -7 -4 -145 -3 -307 l3 -295 705 -3 c388 -1 715 0 728
3 l22 5 0 293 c0 215 -3 296 -12 305 -18 18 -1437 17 -1443 -1z"/>
                <path fill={fillBoxes || "#000000"} d="M7250 3441 c-6 -12 -10 -123 -10 -291 0 -168 4 -279 10 -291 10 -19
29 -19 723 -19 431 0 718 4 727 10 21 13 23 568 1 593 -12 16 -75 17 -727 17
-695 0 -714 0 -724 -19z"/>
                <path fill={fillBoxes || "#000000"} d="M8771 3446 c-24 -29 -33 -133 -29 -341 3 -121 10 -222 16 -237 l12
-28 718 0 c545 0 721 3 730 12 9 9 12 88 12 293 0 260 -1 283 -18 298 -17 16
-82 17 -724 17 -583 0 -708 -2 -717 -14z"/>
                <path fill={fillShip || "#000000"} d="M253 3144 c-3 -10 0 -31 8 -48 10 -23 15 -106 19 -319 l5 -288 899 1
c624 0 903 4 912 11 10 8 14 80 18 297 2 158 6 303 8 322 l3 35 -933 3 c-859
2 -933 1 -939 -14z m223 -118 c16 -12 19 -25 17 -87 -2 -45 -8 -76 -15 -81
-20 -12 -93 -9 -106 4 -7 7 -12 41 -12 83 0 42 5 76 12 83 16 16 80 15 104 -2z
m248 1 c21 -15 32 -87 22 -141 l-7 -36 -55 0 c-49 0 -55 2 -64 26 -6 14 -10
45 -10 69 0 24 4 55 10 69 8 22 16 26 48 26 21 0 46 -6 56 -13z m263 0 c10
-11 16 -103 9 -141 -7 -36 -7 -36 -60 -36 -63 0 -66 4 -66 95 0 93 4 98 62 93
27 -3 51 -8 55 -11z m242 4 c16 -10 27 -114 17 -156 -6 -23 -11 -25 -60 -25
-40 0 -55 4 -60 16 -11 28 -6 150 6 162 14 14 77 16 97 3z m255 -7 c11 -11 16
-35 16 -79 0 -91 -3 -95 -65 -95 -62 0 -65 4 -65 95 0 74 14 95 65 95 18 0 41
-7 49 -16z m-1033 -284 c23 -7 39 -18 42 -31 8 -30 -4 -133 -17 -141 -18 -12
-91 -9 -104 4 -7 7 -12 40 -12 79 0 59 3 70 22 83 26 19 23 19 69 6z m287 -18
c16 -21 16 -123 -1 -146 -7 -9 -31 -20 -53 -23 -54 -7 -74 19 -74 97 0 78 17
100 72 93 24 -3 49 -12 56 -21z m249 14 c10 -10 16 -103 9 -140 -6 -30 -12
-36 -39 -41 -18 -4 -45 -2 -60 4 -26 10 -27 13 -27 81 0 98 10 114 66 107 25
-2 48 -7 51 -11z m252 -3 c8 -6 14 -122 7 -147 -7 -30 -56 -43 -99 -27 -26 10
-27 13 -27 83 0 80 5 93 35 100 20 5 76 -1 84 -9z m253 -6 c10 -16 10 -138 -1
-155 -4 -8 -27 -16 -51 -19 -57 -8 -70 10 -70 95 0 91 10 106 68 98 25 -3 49
-12 54 -19z m250 -1 c11 -16 9 -138 -2 -156 -6 -10 -25 -15 -55 -15 -57 0 -65
11 -65 97 0 84 14 102 74 92 22 -3 44 -12 48 -18z m252 -2 c9 -25 7 -137 -4
-154 -6 -10 -25 -15 -55 -15 -57 0 -65 11 -65 92 0 89 3 93 65 93 39 0 54 -4
59 -16z"/>
                <path fill={fillBoxes || "#000000"} d="M4352 2782 l-162 -2 2 -303 3 -302 708 -3 c477 -1 714 1 728 8 18 10
19 24 19 300 0 190 -4 291 -10 295 -11 7 -994 12 -1288 7z"/>
                <path fill={fillBoxes || "#000000"} d="M5722 2478 l3 -303 714 -3 c523 -1 717 1 728 9 11 10 13 66 11 303
l-3 291 -728 3 -727 2 2 -302z"/>
                <path fill={fillBoxes || "#000000"} d="M7246 2771 c-8 -13 3 -594 11 -600 13 -9 1440 3 1450 12 12 13 16
537 4 570 l-11 27 -724 0 c-399 0 -727 -4 -730 -9z"/>
                <path fill={fillBoxes || "#000000"} d="M8753 2753 c-5 -16 -8 -141 -8 -278 0 -325 -12 -303 167 -307 89 -2
141 1 158 9 14 7 97 85 185 173 88 89 175 168 194 176 27 11 103 14 390 14
272 0 361 3 374 13 15 10 17 27 15 117 l-3 105 -732 3 -733 2 -7 -27z"/>
                <path fill={fillShip || "#000000"} d="M10595 2578 c-11 -6 -33 -23 -50 -38 -16 -15 -47 -37 -68 -50 l-39
-23 -462 2 c-322 2 -471 -1 -491 -9 -17 -6 -109 -90 -205 -186 -165 -163 -178
-174 -225 -184 -75 -15 -6441 -7 -6477 8 -15 6 -98 79 -183 162 l-156 150
-1119 0 -1120 0 0 -440 c0 -306 3 -440 11 -440 6 0 83 -71 171 -157 177 -174
291 -313 355 -432 85 -157 114 -254 116 -386 2 -129 -5 -140 -162 -234 -68
-40 -133 -87 -150 -108 -27 -33 -31 -45 -31 -100 0 -34 5 -74 12 -88 l11 -25
5451 0 5451 1 27 42 c38 60 48 91 48 147 0 105 -101 220 -193 220 -21 0 -49 7
-62 16 -24 16 -25 21 -25 123 0 69 6 125 15 157 9 27 20 65 26 84 20 68 90
196 156 285 82 110 255 290 385 399 245 205 333 284 471 420 81 81 175 184
208 229 96 131 199 356 199 436 l0 31 -937 0 c-664 -1 -944 -4 -958 -12z
m-6480 -1033 c88 -23 170 -72 227 -134 60 -65 93 -132 113 -228 18 -85 18
-130 1 -215 -54 -250 -275 -403 -537 -370 -207 25 -357 157 -404 353 -64 274
82 533 338 595 73 17 192 17 262 -1z m4435 4 c73 -17 166 -67 218 -117 48 -46
112 -144 112 -169 0 -9 -19 -13 -60 -13 -59 0 -59 0 -90 45 -41 60 -112 112
-178 131 -83 24 -203 16 -275 -20 -139 -68 -217 -232 -187 -396 23 -128 82
-210 188 -262 61 -30 74 -33 162 -33 82 0 103 4 151 27 94 43 166 132 185 226
l6 32 -188 2 -189 3 0 55 0 55 253 3 252 2 0 -53 c0 -175 -104 -349 -253 -422
-370 -180 -761 123 -686 530 32 175 160 319 323 366 69 20 189 24 256 8z
m-5620 -419 l0 -410 225 0 225 0 0 -55 0 -55 -285 0 -285 0 0 465 0 465 60 0
60 0 0 -410z m2320 -41 c102 -248 186 -458 188 -466 3 -11 -8 -13 -58 -11
l-62 3 -42 103 -41 102 -230 -2 -229 -3 -41 -102 -40 -103 -62 0 c-35 0 -63 2
-63 4 0 2 81 201 181 442 99 242 185 449 190 462 7 19 16 22 66 22 l58 0 185
-451z m809 440 c181 -41 317 -194 341 -380 24 -192 -46 -360 -188 -456 -103
-69 -136 -76 -389 -81 l-223 -4 0 466 0 466 208 0 c114 0 227 -5 251 -11z
m681 -454 l0 -465 -60 0 -60 0 0 465 0 465 60 0 60 0 0 -465z m635 110 c137
-195 253 -355 257 -355 4 0 8 160 8 355 l0 355 55 0 55 0 0 -465 0 -466 -57 3
-56 3 -254 359 -253 358 0 -361 0 -361 -60 0 -60 0 0 465 0 465 58 0 57 -1
250 -354z"/>
                <path fill={fillShip || "#000000"} d="M3865 1427 c-176 -59 -273 -235 -236 -429 41 -209 237 -329 451 -274
167 43 261 169 261 351 0 159 -62 266 -191 331 -47 24 -69 29 -150 31 -59 2
-110 -2 -135 -10z"/>
                <path fill={fillShip || "#000000"} d="M4915 1155 c-48 -119 -85 -218 -83 -221 3 -2 84 -3 180 -2 l176 3
-87 212 c-48 117 -90 215 -93 218 -3 3 -45 -92 -93 -210z"/>
                <path fill={fillShip || "#000000"} d="M5720 1075 l0 -357 148 4 c171 6 231 23 305 89 71 62 101 133 105
249 3 79 0 105 -18 152 -29 79 -94 148 -172 184 -59 27 -72 29 -215 32 l-153
4 0 -357z"/>
            </g>
        </svg>
    );
};

export default CargoShipSvgIcon;

