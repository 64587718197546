import React from "react";
import {Box, styled, Typography} from "@mui/material";
import {mapColors} from "../../config/mapColors";

const ConnectivityMapLegendContainer = styled(Box)({
    position: "absolute",
    zIndex: 3,
    backdropFilter: "blur(15)",
    display: "flex",
    gap: "20px",
    minHeight: "30px",
    top: "110px",
    left: "70px",
    padding: "15px",
    background: "rgba(39,39,40,.8)",
    borderRadius: "4px",
    flexDirection: "row",
    alignItems: "center",
    "@media(max-width: 768px)": {
        top: "100px",
        left: "0",
    }
});

const ConnectivityMapLegendItem = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

});

const ConnectivityMapLegendItemColor = styled(Box)({
    height: "14px",
    width: "14px",
    marginRight: "12px",
    borderRadius: "4px",
});

const ConnectivityMapLegendItemText = styled(Typography)({
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#FFFFFF"
});

const ConnectivityMapLegend = ():JSX.Element => {
    return (
        <ConnectivityMapLegendContainer>
            <ConnectivityMapLegendItem>
                <ConnectivityMapLegendItemColor sx={{
                    background: mapColors.available
                }}/>
                <ConnectivityMapLegendItemText>Available</ConnectivityMapLegendItemText>
            </ConnectivityMapLegendItem>
            <ConnectivityMapLegendItem>
                <ConnectivityMapLegendItemColor sx={{
                    background: mapColors.notAvailable
                }}/>
                <ConnectivityMapLegendItemText>Not Available</ConnectivityMapLegendItemText>
            </ConnectivityMapLegendItem>
        </ConnectivityMapLegendContainer>
    );
};

export default ConnectivityMapLegend;