import React from "react";
import {Box} from "@mui/material";
import sailorTalk1 from "../../assets/sailorTalk1.png";
import sailorTalk2 from "../../assets/sailorTalk2.png";
import {FleetConnexElementContainer,
    FleetConnexElementText,
    FleetConnexElementTextTitle} from "../FleetConnex/FleetConnexElementFirst";


const SailorTalkElementFirst = ():JSX.Element => {
    return (
        <FleetConnexElementContainer>
            <Box sx={{
                height: "70vh",
                background: `url(${sailorTalk1}) no-repeat 0 0`,
                backgroundSize: "cover",
            }}/>
            {window.innerWidth >= 768 &&  <Box sx={{
                height: "70vh",
                background: `url(${sailorTalk2}) no-repeat 40%`,
                backgroundSize: "cover",
            }}/>}
            {window.innerWidth < 768 &&  <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>
                    SAILORTALK SERVICE
                </FleetConnexElementTextTitle>
                <p>Sailortalk is a global roaming service aimed at seafarers allowing them 
                    to communicate with their family and friends whether they are onboard*, 
                    or in port or sailing in coastal waters.</p>
                <p>Using the Sailortalk sim card seafarers can make and receive phone calls,
                    send and receive SMS messages and use 
                    data services wherever they may be.</p>
                <p>*assuming the ship on which they are 
                    serving is equipped with the necessary equipment.</p>
                <p>SAILORTALK is the complete communication solution designed for seafarers as it facilitates voice,
                    SMS and data services via the ship’s satellite system (SAILORTALK ONBOARD - for those vessels equipped with the
                    necessary onboard equipment) and via shore cellular networks (SAILORTALK ROAMING)
                    when the ship is in range of shoreside GSM networks.</p>
                <p>As such SAILORTALK is a comprehensive solution at cost effective rates without
                    the need to continually swap sim cards with all the hassle that can involve.</p>
            </FleetConnexElementText>}
        </FleetConnexElementContainer>
    );
};

export default SailorTalkElementFirst;