import React from "react";
import {styled} from "@mui/material";
import Contacts from "../Main/Contacts";
import ShipTalkElementSecond from "./ShipTalkElementSecond";
import ShipTalkStatement from "./ShipTalkStatement";
import ShipTalkElementThird from "./ShipTalkElementThird";

const ShipTalkContainer = styled("main")({
    padding:0,
    marginBottom: "40px",
});

const ShipTalk = ():JSX.Element => {
    return <ShipTalkContainer>
        <ShipTalkStatement/>
        <ShipTalkElementSecond/>
        <ShipTalkElementThird/>
        <Contacts/>
    </ShipTalkContainer>;
};

export default ShipTalk;