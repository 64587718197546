import React from "react";
import shipTalkVideoPoster from "../../assets/shipTalkVideoPoster.png";
import {StatementText, StatementTitle} from "../Main/Statement";
import {styled} from "@mui/material";

const SailorTalkStatementContainer = styled("section")({
    position: "relative",
    width: "100%",
    height: "fit-content",
    "@media(max-width: 768px)":{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        "& video":{
            height: "100%",
            width: "auto",
            marginLeft: "-130%"
        }
    },
    "@media(max-height: 400px)":{
        "& video": {
            marginLeft: "0"
        }
    }
});

const ShipTalkStatement = ():JSX.Element => {
    return (
        <SailorTalkStatementContainer>
            <video width={"100%"} autoPlay muted loop preload="auto" playsInline poster={shipTalkVideoPoster}>
                <source src={require("../../assets/shipTalkVideo.mp4")}/>
            </video>
            <StatementText>
                <StatementTitle sx={{
                    textTransform: "none"
                }}>
                    MARITIME VoIP SERVICE
                </StatementTitle>
            </StatementText>
        </SailorTalkStatementContainer>
    );
};

export default ShipTalkStatement;