import React, {useEffect} from "react";
import {Box, Button, styled} from "@mui/material";
import {initMap} from "./initMap";
import ConnectivityMapLegend from "./ConnectivityMapLegend";
import {useNavigate} from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import {FleetConnexStatementButtonContainer} from "../FleetConnex/FleetConnexStatement";

const ConnectivityMapContainer = styled(Box)({
    position: "relative",
});

const ConnectivityMapBackButton = styled(Button)({
    color: "#FFFFFF",
    textShadow: "#000000 1px 0 10px",
    fontSize: "1.1rem",
    background: "rgba(33,33,33,.6)",
    transition: "background .3s ease",
    "&:hover":{
        background: "rgba(33,33,33,.8)",
    },
    "@media(max-width: 768px)":{
        fontSize: "0.75rem",
    }
});

const ConnectivityMap = ():JSX.Element => {

    const navigate = useNavigate();

    useEffect(() => {
        initMap();
    },[]);
    
    return (
        <ConnectivityMapContainer>
            <Box id="chart-container"
                sx={{
                    height: "calc(100vh - 80px)",
                    width: "100%"
                }}
            />
            <ConnectivityMapLegend/>
            <FleetConnexStatementButtonContainer>
                <ConnectivityMapBackButton onClick={() => navigate("/fleetConnex")}>
                    <KeyboardArrowLeftOutlinedIcon htmlColor={"#FFFFFF"}/>
                Back
                </ConnectivityMapBackButton>
            </FleetConnexStatementButtonContainer>
        </ConnectivityMapContainer>
    );
};

export default ConnectivityMap;