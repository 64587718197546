import React from "react";
import {Box} from "@mui/material";
import LegalTitle from "./LegalTitle";
import {LegalContent} from "./LegalContent";
import {NavLink} from "react-router-dom";


const TermsAndConditions = ():JSX.Element => {
    return (
        <Box>
            <LegalTitle title={"Terms & Conditions"} lastUpdated={"Last Updated 2022-10-17"}/>
            <LegalContent>
                <div className="container px-6 pb-6 pt-6 space-y-4"><p>Welcome to pentonet.com!</p>
                    <p>These terms and conditions outline the rules and regulations for the use of Pentonet Oy's Website,
                    located at https://pentonet.com.</p>
                    <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use
                    pentonet.com if you do not agree to take all of the terms and conditions stated on this page.</p>
                    <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer
                    Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person on this website and
                    compliant with the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”,
                    refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All
                    terms refer to the offer, acceptance and consideration of payment necessary to undertake the process
                    of our assistance to the Client in the most appropriate manner for the express purpose of meeting
                    the Client’s needs in respect of provision of the Company’s stated services, in accordance with and
                    subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the
                    singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore
                    as referring to same.</p>
                    <p><strong>Cookies</strong></p>
                    <p>We employ the use of cookies. By accessing pentonet.com, you agreed to use cookies in agreement with
                    the Pentonet Privacy Policy.</p>
                    <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies
                    are used by our website to enable the functionality of certain areas to make it easier for people
                    visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                    <p><strong>License</strong></p>
                    <p>Unless otherwise stated, Pentonet and/or its licensors own the intellectual property rights for all
                    material on pentonet.com. All intellectual property rights are reserved. You may access this from
                    pentonet.com for your own personal use subjected to restrictions set in these terms and
                    conditions.</p>
                    <p>You must not:</p>
                    <ul>
                        <li>Republish material from pentonet.com</li>
                        <li>Sell, rent or sub-license material from pentonet.com</li>
                        <li>Reproduce, duplicate or copy material from pentonet.com</li>
                        <li>Redistribute content from pentonet.com</li>
                        <li>Pentonet reserves the right to monitor all Comments and to remove any Comments which can be
                        considered inappropriate, offensive or causes breach of these Terms and Conditions.
                        </li>
                    </ul>
                    <p>You warrant and represent that:</p>
                    <ul>
                        <li>You are entitled to post the Comments on our website and have all necessary licenses and
                        consents to do so;
                        </li>
                        <li>The Comments do not invade any intellectual property right, including without limitation
                        copyright, patent or trademark of any third party;
                        </li>
                        <li>The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful
                        material which is an invasion of privacy
                        </li>
                        <li>The Comments will not be used to solicit or promote business or custom or present commercial
                        activities or unlawful activity.
                        </li>
                        <li>You hereby grant Pentonet a non-exclusive license to use, reproduce, edit and authorize others
                        to use, reproduce and edit any of your Comments in any and all forms, formats or media.
                        </li>
                    </ul>
                    <p><strong>Hyperlinking to our Content</strong></p>
                    <p>The following organizations may link to our Website without prior written approval:</p>
                    <p>Government agencies;
                    Search engines;
                    News organizations;
                    Online directory distributors may link to our Website in the same manner as they hyperlink to the
                    Websites of other listed businesses; and
                    System wide Accredited Businesses except soliciting non-profit organizations, charity shopping
                    malls, and charity fundraising groups which may not hyperlink to our Web site.
                    These organisations may link to our home page, to publications or to other Website information so
                    long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship,
                    endorsement or approval of the linking party and its products and/or services; and (c) fits within
                    the context of the linking party’s site.</p>
                    <p>We may consider and approve other link requests from the following types of organisations:</p>
                    <p>commonly-known consumer and/or business information sources;
                    dot.com community sites;
                    associations or other groups representing charities;
                    online directory distributors;
                    internet portals;
                    accounting, law and consulting firms; and
                    educational institutions and trade associations.
                    We will approve link requests from these organisations if we decide that: (a) the link would not
                    make us look unfavourably to ourselves or to our accredited businesses; (b) the organisation does
                    not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink
                    compensates the absence of Pentonet; and (d) the link is in the context of general resource
                    information.</p>
                    <p>These organisations may link to our home page so long as the link: (a) is not in any way deceptive;
                    (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its
                    products or services; and (c) fits within the context of the linking party’s site.</p>
                    <p>If you are one of the organisations listed in paragraph 2 above and are interested in linking to our
                    website, you must inform us by sending an e-mail to Pentonet. Please include your name, your
                    organisation name, contact information as well as the URL of your site, a list of any URLs from
                    which you intend to link to our Website, and a list of the URLs on our site to which you would like
                    to link. Wait 2-3 weeks for a response.</p>
                    <p><strong>Approved organisations may hyperlink to our Website as follows:</strong></p>
                    <p>By use of our corporate name; or
                    By use of the uniform resource locator being linked to; or</p>
                    <p>By use of any other description of our Website being linked to that makes sense within the context
                    and format of content on the linking party’s site.</p>
                    <p>No use of Pentonet&rsquo;s logo or other artwork will be allowed for linking absent a trademark license
                    agreement.</p>
                    <p><strong>iFrames</strong></p>
                    <p>Without prior approval and written permission, you may not create frames around our Webpages that
                    alter in any way the visual presentation or appearance of our Website.</p>
                    <p><strong>Content Liability</strong></p>
                    <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect
                    and defend us against all claims that is rising on your Website. No link(s) should appear on any
                    Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise
                    violates, or advocates the infringement or other violation of, any third party rights.</p>
                    <p><strong>Your Privacy</strong></p>
                    <p><strong>Please read <NavLink to={"/legal/privacy-policy"}>Privacy Policy</NavLink></strong></p>
                    <p><strong>Reservation of Rights</strong></p>
                    <p>We reserve the right to request that you remove all links or any particular link to our Website. We
                    also reserve the right to amend these terms and conditions and its linking policy at any time. By
                    continuously linking to our Website, you agree to be bound to and follow these linking terms and
                    conditions.</p>
                    <p><strong>Removal of links from our website</strong></p>
                    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and
                    inform us any moment. We will consider requests to remove links but we are not obligated to or so or
                    to respond to you directly.</p>
                    <p>We do not ensure that the information on this website is correct, we do not warrant its completeness
                    or accuracy; nor do we promise to ensure that the website remains available or that the material on
                    the website is kept up to date.</p>
                    <p><strong>Disclaimer</strong></p>
                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and
                    conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                    <ul>
                        <li>limit or exclude our or your liability for death or personal injury;</li>
                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or
                        </li>
                        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                        <li>The limitations and prohibitions of liability set in this Section and elsewhere in this
                        disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising
                        under the disclaimer, including liabilities arising in contract, in tort and for breach of
                        statutory duty.
                        </li>
                    </ul>
                    <p>As long as the website and the information and services on the website are provided free of charge,
                    we will not be liable for any loss or damage of any nature.</p></div>
            </LegalContent>
        </Box>
    );
};

export default TermsAndConditions;