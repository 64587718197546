import {mapColors} from "./mapColors";

export const mapConfig = [{
    name:"Denmark", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Finland", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Norway", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Sweden", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Lithuania", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Belgium", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Cyprus", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Estonia", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Faroe Islands", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"France", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Germany", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Greece", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Iceland", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Ireland", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Italy", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Latvia", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Malta", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Montenegro", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Netherlands", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Poland", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Portugal", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Puerto Rico", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Romania", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Slovenia", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Spain", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Turkey", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"United Kingdom", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"United States", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Croatia", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Bulgaria", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Jersey", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Bangladesh", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Malaysia", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Korea", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Taiwan", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Thailand", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Hong Kong", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Pakistan", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Albania", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Israel", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Singapore", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"South Africa", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Ukraine", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Ghana", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Mozambique", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Tanzania", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Isle of Man", itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Indonesia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Australia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Brunei",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Cambodia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Canada",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"China",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Desirade",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"French Guiana",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Gibraltar",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Greenland",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Guadeloupe",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"India",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Kuwait",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Les Saintes",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Macau",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Marie-Galante",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Martinique",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Mayotte",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Myanmar",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"New Zealand",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Oman",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Philippines",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Reunion",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Saint Barthelemy",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Saint Martin",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Sri Lanka",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Vietnam",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Bosnia & Herzegovina",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Brazil",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Dominican Republic",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Guatemala",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Honduras",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Japan",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Mexico",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Russia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Uruguay",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Aland Islands",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Bahrain",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Fiji",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Saudi Arabia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Argentina",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Ecuador",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"El Salvador",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Qatar",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Georgia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Chile",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Colombia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Costa Rica",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Nicaragua",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Panama",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Peru",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name:"Venezuela",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Bahamas",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Barbados",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Cayman Islands",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Egypt",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Grenada",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "British Virgin Islands",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Montserrat",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Palestine",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Dominica",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Saint Lucia",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "United Arab Emirates",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Antigua & Barbuda",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Guam",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Iran",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Jamaica",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Saint Kitts & Nevis",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Saint Vincent & Grenadines",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Netherlands Antilles",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Turks & Caicos Islands",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Anguilla",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Cape Verde",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Guernsey",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Ivory Coast",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Madagascar",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Mauritius",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Senegal",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Sierra Leone",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Guyana",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Int'l Waters T Mex Gulf",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Int'l Waters T NorthSea",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Int'l Waters TM Nor CS",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Jordan",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Iraq",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Timor-Leste",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
},
{
    name: "Nigeria",itemStyle: {
        areaColor: mapColors.available,
    },
    emphasis: {
        itemStyle: {
            areaColor: mapColors.availableHover, borderColor: mapColors.availableHover
        }
    },
}];
