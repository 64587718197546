import React from "react";
import {FleetConnexElementText, FleetConnexElementTextTitle} from "../FleetConnex/FleetConnexElementFirst";
import {Box, styled} from "@mui/material";
import sailorTalk2 from "../../assets/sailorTalk2.png";
import {FleetConnexElementSecondContainer} from "../FleetConnex/FleetConnexElementSecond";

const SailorTalkElementSecondLi = styled(FleetConnexElementText)({
    "& li p":{
        textTransform: "none"
    }
});

const SailorTalkElementSecond = ():JSX.Element => {
    return (
        <FleetConnexElementSecondContainer>
            {window.innerWidth >= 768 && <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>
                    SAILORTALK SERVICE
                </FleetConnexElementTextTitle>
                <p>Sailortalk is a global roaming service aimed at seafarers allowing them to communicate with their family and friends whether they are onboard*,
                    or in port or sailing in coastal waters.</p>
                    
                <p>Using the Sailortalk sim card seafarers can make and receive phone calls, 
                    send and receive SMS messages and use data services wherever they may be.
                        *assuming the ship on which they are serving is equipped with the necessary equipment.</p>
                    
                <p>SAILORTALK is the complete communication solution designed for seafarers as it facilitates voice, 
                    SMS and data services via the ship’s satellite system (SAILORTALK ONBOARD - for those vessels equipped with the necessary onboard equipment) 
                    and via shore cellular networks (SAILORTALK ROAMING) when the ship is in range of shoreside GSM networks.</p>

                <p>As such SAILORTALK is a comprehensive solution at cost effective rates without
                        the need to continually swap sim cards with all the hassle that can involve.</p>
            </FleetConnexElementText>}
            {window.innerWidth < 768 &&  <Box sx={{
                height: "70vh",
                background: `url(${sailorTalk2}) no-repeat center`,
                backgroundSize: "cover",
            }}/>}
            <SailorTalkElementSecondLi>
                <FleetConnexElementTextTitle variant={"h6"}>
                    GLOBAL COVERAGE
                </FleetConnexElementTextTitle>
                <p>One Communication service across the GLOBE,
                    at sea and ashore Voice calls and SMS messages (inbound and outbound), Data service and Voicemail.
                    There are two SAILORTALK sim cards:
                </p>
                <ul>
                    <li>
                        <p>SAILORTALK CLASSIC sim card that offers a
                        wide range of voice and data plan options</p></li>
                    <li>
                        <p>SAILORTALK CHAT sim card which provides
                    Unlimited Chat using WhatsApp, Viber, Line, WeChat,
                        FaceBook Messenger for just US$28 per year from 90 Countries around the World.</p>
                    </li>
                </ul>
                <p>The Chat sim card also offers an annual Data coupon giving with data
                    costing just $0.021 per MB in 62 Countries.
                    Data usage outside these 62 Countries is based on standard PAYG rates.</p>
            </SailorTalkElementSecondLi>
        </FleetConnexElementSecondContainer>
    );
};

export default SailorTalkElementSecond;