import React from "react";
import sdWanVideoPoster from "../../assets/sdWanVideoPoster.png";
import {Box, styled} from "@mui/material";

const SdWanVideoContainer = styled(Box)({
    height: "calc(100vh - 80px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
});

const SdWanVideo = ():JSX.Element => {
    return (
        <SdWanVideoContainer>
            <video autoPlay loop controls={true} preload="auto" playsInline poster={sdWanVideoPoster}>
                <source src={require("../../assets/sdWanVideo.mp4")}/>
            </video>
        </SdWanVideoContainer>
    );
}; 

export default SdWanVideo;