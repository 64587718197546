import fleetConnex1 from "../../assets/fleetConnex1.png";
import React from "react";
import {FleetConnexElementContainer,
    FleetConnexElementText,
    FleetConnexElementTextTitle} from "./FleetConnexElementFirst";
import {styled} from "@mui/material";

const FleetConnexElementThirdContainer = styled(FleetConnexElementContainer)({
    display: "block",
    height: "auto",
});

const FleetConnexElementThird = ():JSX.Element => {
    return (
        <FleetConnexElementThirdContainer>
            <img
                alt={"Portal_IMG"}
                width={"100%"}
                src={fleetConnex1}
            />
            <FleetConnexElementText
                sx={{
                    background: "rgba(33,33,33,.9)",
                    "& li":{
                        textTransform: "none",
                    }
                }}
            >
                <FleetConnexElementTextTitle variant={"h6"}>
                    MANAGEMENT PORTAL
                </FleetConnexElementTextTitle>
                <ul>
                    <li><p>End to End management. Firmware all developed in-house for complete control and management
                        of systems</p>
                    </li>
                    <li><p>Pentonet Portal to control the Fleet-Connex service through remote updates,
                        whitelist/blacklist configuration and usage caps</p>
                    </li>
                    <li><p>Email notifications and status warnings for usage.</p></li>
                </ul>
            </FleetConnexElementText>
        </FleetConnexElementThirdContainer>
    );
};

export default FleetConnexElementThird;