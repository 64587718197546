import React from "react";
import {Box} from "@mui/material";

export const MicrosoftOfficeIcon = ():JSX.Element => {
    return (
        <Box sx={{
            transform:  window.innerWidth > 768 ?"scale(1.3)" : "scale(1)",
            height: "32px",
            width: "32px",
            background: "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='512px' height='512px' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath style='opacity:0.987' fill='%23FFFFFF' d='M 299.5,59.5 C 307.022,59.4759 314.355,60.6425 321.5,63C 352.989,72.9407 384.655,82.2741 416.5,91C 417.701,91.9025 418.535,93.0692 419,94.5C 419.667,202.167 419.667,309.833 419,417.5C 418.167,418.333 417.333,419.167 416.5,420C 383.108,429.464 349.774,439.131 316.5,449C 310.599,450.763 304.599,451.429 298.5,451C 229.812,426.383 161.479,400.883 93.5,374.5C 92.8076,373.975 92.3076,373.308 92,372.5C 91.5,293.834 91.3333,215.167 91.5,136.5C 160.924,110.807 230.257,85.1398 299.5,59.5 Z M 297.5,122.5 C 298.833,122.5 300.167,122.5 301.5,122.5C 301.5,215.833 301.5,309.167 301.5,402.5C 233.506,393.049 165.506,383.549 97.5,374C 99.6705,372.414 102.004,371.08 104.5,370C 124.085,362.195 143.752,354.695 163.5,347.5C 163.5,283.167 163.5,218.833 163.5,154.5C 208.423,144.435 253.09,133.768 297.5,122.5 Z'/%3E%3C/g%3E%3C/svg%3E%0A\") no-repeat",
            backgroundSize: "contain",
        }}/>
    );
};