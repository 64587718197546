import * as echarts from "echarts";
import "echarts-gl";
import {mapConfig} from "../../config/mapConfig";
import {mapColors} from "../../config/mapColors";

export const  initMap = () => {
    const dom = document?.getElementById("chart-container");
    const myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
    });
    let option;
    myChart.showLoading();
    fetch("https://cdn.jsdelivr.net/gh/apache/echarts-www@master/asset/map/json/world.json").then((e) => {
        e.json().then((geoJson) => {
            myChart.hideLoading();
            echarts.registerMap("world", geoJson);
            option = {
                backgroundColor: mapColors.background,
                title: {
                    text: "",
                    left: "center",
                    textStyle: {
                        color: mapColors.textHoverColor
                    }
                },
                geo: {
                    map: "world",
                    roam: true,
                    label: {
                        show: true,
                        color: mapColors.textColor,
                    },
                    regions: mapConfig,
                    zoom: 2,
                    silent: false,
                    emphasis: {
                        itemStyle: {
                            areaColor: mapColors.textColor,
                        },
                        label: {
                            color: mapColors.textHoverColor,
                        }
                    },
                    itemStyle: {
                        borderColor: mapColors.background,
                        areaColor: mapColors.notAvailable,
                    }
                },
                series: []
            };
            myChart.setOption(option);
        });
    });
};