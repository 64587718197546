import React from "react";
import {Box, styled, Typography} from "@mui/material";
import starlinkCompanion1 from "../../assets/starlinkCompanion1.png";
import starlinkCompanion2 from "../../assets/starlinkCompanion2.png";
import starlinkCompanion3 from "../../assets/starlinkCompanion3.png";
import starlinkCompanion4 from "../../assets/starlinkCompanion4.png";
import PDFButtons from "./PDFButtons";
import PDFButtonsFC from "./PDFButtonsFC";
const StarlinkCompanionElementFirstContainer = styled(Box)({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    padding: "0 5%",
    columnGap: "20px",
    "@media(max-width: 768px)":{
        gridTemplateColumns: "1fr",
        padding: "0 1%",
    }
});

const StarlinkCompanionElementFirstElement = styled(Box)({
    background: "rgba(33,33,33,.9)",
    padding: "10px",
    boxSizing: "border-box",
    position: "relative",
    "@media(max-width: 768px)":{
        paddingBottom: "80px",
    }
});

const StarlinkCompanionElementFirstTitle = styled(Typography)({
    color: "#FFFFFF",
    fontSize: "22px",
    fontWeight: 600,
});

const StarlinkCompanionElementFirstText = styled(Box)({
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: 400,
});

const StarlinkCompanionElementFirst = ():JSX.Element => {
    return (
        <StarlinkCompanionElementFirstContainer>
            <StarlinkCompanionElementFirstElement>
                <Box sx={{
                    height: "20vh",
                    background: `url(${starlinkCompanion1}) no-repeat center`,
                    backgroundSize: "contain",
                }}/>
                <StarlinkCompanionElementFirstTitle>
                    CLOUD PORTAL TO MANAGE THE SHIPS
                </StarlinkCompanionElementFirstTitle>
                <StarlinkCompanionElementFirstText>
                    <p>Edge OS runs on every network node in the Kognitive Edge ecosystem.
                    Frontend control of physical interfaces and configuration of network parameters complement innovative WAN controls for satellite,
                    5G/LTE, WiFi, and hardwired network sources.
                        Quick configuration of security rules,
                        QoS using deep packet inspection, and setting up VLANs can be accomplished on-board and the familiar
                        Edge OS interface can be accessed seamlessly through the cloud.</p>
                    <p>The Service Portal gives a broad overview of all the Edge nodes/devices and connected systems in your fleet.
                        Monitor high-level connectivity and usage patterns across site with
                        Advanced Analytics and instantly connect to any device on the network
                        through an intuitive web portal.
                        Advanced algorithms and AI collect and manage cellular and satellite connectivity globally,
                        providing asset physical location overlayed with network availability
                        to aid in troubleshooting and full transparency of network availability.
                    </p>
                </StarlinkCompanionElementFirstText>
            </StarlinkCompanionElementFirstElement>
            <StarlinkCompanionElementFirstElement>
                <Box sx={{
                    height: "20vh",
                    background: `url(${starlinkCompanion2}) no-repeat center`,
                    backgroundSize: "contain",
                }}/>
                <StarlinkCompanionElementFirstTitle>
                    SDWAN EDGE SERVER
                </StarlinkCompanionElementFirstTitle>
                <StarlinkCompanionElementFirstText>
                    <p>
                        Provide a powerful On-Board addition to network infrastructure and enable Best in Class SD-WAN capabilities through Edge OS.
                        The fully featured Pro edition has the flexibility, inputs, and horsepower to be the cornerstone of a network installation,
                        providing an innovative SD-WAN frontend experience. With multiple WAN link bandwidth bonding,
                        Point of Presence (PoP) drop-off, fully featured firewall, and Virtual LAN (VLAN) configurability,
                        the EdgePro transforms traditional networks with cutting edge connectivity.
                    </p>
                    <PDFButtons/>
                </StarlinkCompanionElementFirstText>
            </StarlinkCompanionElementFirstElement>
            <StarlinkCompanionElementFirstElement>
                <Box sx={{
                    height: "20vh",
                    background: `url(${starlinkCompanion3}) no-repeat center`,
                    backgroundSize: "cover",
                }}/>
                <StarlinkCompanionElementFirstTitle>
                    STARLINK
                </StarlinkCompanionElementFirstTitle>
                <StarlinkCompanionElementFirstText>
                    <p>
                        Delivers high-speed, low-latency internet both in-motion and while anchored.
                        Enjoy streaming movies, making video calls, playing video games and more - all while out on the water.
                        Download 40-220+ MBPS, upload 8-25+ MBPS and latency less than 99 MS.
                        Three different data packages available: 50GB, 1 TB and 5 TB, where 1 TB and 5 TB deals are best for boats with crews, supporting streaming,
                        video calls, and more. Integrate Starlink into our SD-WAN router via direct ethernet connection.
                        The Flat High Performance comes with an easy-to-install mount.
                        We will help you to define the best install location on your vessel.
                    </p>
                </StarlinkCompanionElementFirstText>
            </StarlinkCompanionElementFirstElement>
            <StarlinkCompanionElementFirstElement>
                <Box sx={{
                    height: "20vh",
                    background: `url(${starlinkCompanion4}) no-repeat center`,
                    backgroundSize: "contain",
                }}/>
                <StarlinkCompanionElementFirstTitle>
                    FLEETCONNEX
                </StarlinkCompanionElementFirstTitle>
                <StarlinkCompanionElementFirstText>
                    <p>
                        The FleetConnex family from Pentonet is a standalone range of industrial grade,
                        multi-band router solutions providing best in class connectivity at speeds of up to 600Mbps over extended ranges global bands from 4G,
                        3G and 2G standard cellular networks.
                    </p>
                    <p>
                            It includes enhanced connectivity and security features to ensure peace of mind for our customers.
                        It also comes with support in the Pentonet Portal which allows for direct management and monitoring of the system.
                        Systems built to be plug and play with PoE for LAN side connectivity and all prepared prior to shipping.
                    </p>
                    <PDFButtonsFC/>
                </StarlinkCompanionElementFirstText>
            </StarlinkCompanionElementFirstElement>
        </StarlinkCompanionElementFirstContainer>
    );
};

export default StarlinkCompanionElementFirst;