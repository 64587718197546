import React from "react";
import {Box, styled} from "@mui/material";
import mainPageVideoPoster from "../../assets/mainPageVideoPoster.png";

const StatementContainer = styled("section")({
    position: "relative",
    width: "100%",
    height: "fit-content",
    "@media(max-width: 768px)":{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        "& video":{
            height: "100%",
            width: "auto",
            marginLeft: "-80%"
        }
    },
    "@media(max-height: 400px)":{
        "& video": {
            marginLeft: "0"
        }
    }
});


export const StatementTitle = styled(Box)({
    textTransform: "uppercase",
    fontSize: "3.75rem",
    fontWeight: 700,
    "@media(max-width: 1024px)":{
        fontSize: "24px",
    },
    "@media(max-width: 768px)":{
        fontSize: "20px",
    },
});

export const StatementText = styled(Box)({
    position: "absolute",
    top: "calc(100px + 5%)",
    width: "100%",
    textAlign: "start",
    paddingLeft: "20px",
    maxWidth: "calc(100vw - 100px)",
    "& *":{
        color: "#FFFFFF",
        textShadow: "#000000 1px 0 10px",
    }
});


const Statement = ():JSX.Element => {
    return (
        <StatementContainer>
            <video width={"100%"} autoPlay muted loop preload="auto" playsInline poster={mainPageVideoPoster}>
                <source src={require("../../assets/mainPageVideo.mp4")}/>
            </video>
            <StatementText>
                <StatementTitle>
                    Smarter communications<br/>
                    for ship and shore</StatementTitle>
            </StatementText>
        </StatementContainer>
    );
};

export default Statement;