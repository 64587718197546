import React from "react";
import starlinkCompanionBackground1 from "../../../assets/starlinkCompanionBackground1.png";
import {Box, styled} from "@mui/material";
import vessel1 from "../../../assets/vessel1.png";

const OfficeElementContainer = styled(Box)({
    position: "relative",
    display: "grid",
    gridTemplateRows: "2fr 3fr 1fr",
    rowGap: "10px",
    textAlign: "center",
});

const StarlinkCompanionElementSecondOffice = styled(Box)({
    position: "relative",
    width: "120%",
    height: "120%",
    "@media(max-width: 768px)":{
        margin: "auto",
        overflow: "hidden",
        width: "100%",
        height: "100%",
    }
});

const StarlinkCompanionElementSecondOfficeVesselBox = styled(Box)({
    width: "150%",
    height: "150%",
    marginLeft: "-20%",
    "&:nth-of-type(2n)":{
        marginLeft: "-20%",
    },
    "@media(max-width: 768px)":{
        margin: "auto",
        width: "100%",
        height: "100%",
    }
});

const OfficeElementUnderText = styled(Box)({
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    color: "#FFFFFF",
    justifyContent: "center",
});

const OfficeElement = ():JSX.Element => {
    return (
        <OfficeElementContainer>
            <StarlinkCompanionElementSecondOffice
                sx={{
                    background:  `url(${starlinkCompanionBackground1}) no-repeat center`,
                    backgroundSize: "contain",
                }}
            >
            </StarlinkCompanionElementSecondOffice>
            <Box sx={{
                display: "grid",
                gridTemplateRows: "1fr 1fr 1fr",
                gridTemplateColumns: "1fr 1fr",
            }}>
                {new Array(6).fill("").map((opt, index) =>
                    <StarlinkCompanionElementSecondOfficeVesselBox
                        key={"vesselImg-" + index}
                        sx={{
                            background:  `url(${vessel1}) no-repeat`,
                            backgroundSize: "contain",
                        }}
                    />
                )}
            </Box>
            <OfficeElementUnderText>Vessel Services</OfficeElementUnderText>
        </OfficeElementContainer>
    );
};

export default OfficeElement;