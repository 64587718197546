import React, {useEffect, useState} from "react";
import {Box, styled, Typography} from "@mui/material";
import {getStories} from "../../helpers/api/getStories";
import {StoryInterface} from "../../interfaces/StoryInterface";
import {storiesReserve} from "./storiesReserve";
import StoriesElementsLoader from "./StoriesElementsLoader";
import SelectedStory from "./SelectedStory";
import StoriesItem from "./StoriesItem";

const StoriesContainer = styled("main")({
    marginTop: "120px",
    padding:0,
    marginBottom: "40px",
    minHeight: "calc(100vh - 240px)",
});

const StoriesTitle = styled(Typography)({
    textTransform: "uppercase",
    fontSize: "3.75rem",
    fontWeight: 700,
    textAlign: "center",
    color: "#FFFFFF",
    "@media(max-width: 768px)":{
        fontSize: "20px",
    },
});

const StoriesElementsContainer = styled("section")({
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "4.27rem",
    padding: "0px 1.22rem",
    minHeight: "calc(100vh - 315px)",
    "@media(max-width: 768px)":{
        gridTemplateColumns: "1fr 1fr",
        gap: "1.25rem",
    }
});


const StoriesItemContainer = styled(Box)({
    "& *":{
        textDecoration: "none",
    },
});

const Stories = ():JSX.Element => {

    const columns = window.innerWidth > 768 ? 5 : 2;
    
    const [stories, setStories] = useState<StoryInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedStory, setSelectedStory] = useState<StoryInterface | null>(null);
    
    useEffect(() => {
        getStories().then((response) => {
            if(response.status <= 300){
                response.json().then((resource: { data: StoryInterface[], meta: {pagination: {
                            page: number;
                            pageSize: number;
                            pageCount: number;
                            total: number;
                        } }}) => {
                    if(resource.data && resource.data.length){
                        setStories(resource.data.sort((a,b) => b.id - a.id));
                        setLoading(false);
                    }else{
                        setStories(storiesReserve.reverse());
                        setLoading(false);
                    }
                });
            }else{
                setStories(storiesReserve.reverse());
                setLoading(false);
            }
        }).catch(() => {
            setStories(storiesReserve.reverse());
            setLoading(false);
        });
    },[]);
    
    const handleSelect = (story: StoryInterface):void => {
        setSelectedStory(story);
    };

    const handleBack = ():void => {
        setSelectedStory(null);
    };
    
    const renderContent = ():React.ReactNode => {
        if(loading){
            return <StoriesElementsLoader/>;
        }
        if(selectedStory){
            return <SelectedStory selectedStory={selectedStory} onBack={handleBack}/>;
        }
        return (
            <>
                <StoriesTitle>
                Stories
                </StoriesTitle>
                <StoriesElementsContainer>
                    {renderStories()}
                </StoriesElementsContainer>
            </>
        );
    };

    const renderStories = ():React.ReactNode => {
        let preparedArr: StoryInterface[][] = stories.map((opt, index: number, arr) => (!index || !(index % Math.round(arr.length / columns))) ? arr.slice(index, index + Math.round(arr.length / columns))  : null).filter((opt) => !!opt) as StoryInterface[][];
        if(stories.length < columns){
            preparedArr = [...stories.map((opt) => [opt])];
        }
        if(preparedArr.length > columns){
            preparedArr = (([preparedArr.slice(0,3).flat(2).map((opt, index, arr) => !index || !(index % Math.round(arr.length / 2)) ? arr.slice(index, index + arr.length / 2) : null).filter((opt) => !!opt), preparedArr.slice(preparedArr.length / 2,preparedArr.length)]).flat(1) as StoryInterface[][]);
        }
        return preparedArr.map((opt: StoryInterface[], i) => {
            return (
                <StoriesItemContainer key={"StoriesItemContainer-" + i}
                    sx={{
                        marginTop: i % 2 ? ( window.innerWidth <= 768 ? "2.94rem" : "9.44rem") : "0"
                    }}
                >
                    {opt.map((el: StoryInterface, index) => {
                        return <StoriesItem key={"story_"+ index} el={el} handleSelect={handleSelect} />;
                    })}
                </StoriesItemContainer>
            );
        });
    };
    return <StoriesContainer>
        {renderContent()}
    </StoriesContainer>;
    
};

export default Stories;