import React from "react";
import {FleetConnexElementContainer,
    FleetConnexElementText,
    FleetConnexElementTextTitle} from "./FleetConnexElementFirst";
import {Box, styled} from "@mui/material";
import fleetConnex2 from "../../assets/fleetConnex2.png";

export const FleetConnexElementSecondContainer = styled(FleetConnexElementContainer)({
    gridTemplateColumns: "1fr 1fr",
});

const FleetConnexElementSecond = ():JSX.Element => {
    return (
        <FleetConnexElementSecondContainer>
            {window.innerWidth >= 768 && <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>
                   FLEETCONNEX INFO
                </FleetConnexElementTextTitle>
                <p>The FleetConnex family from Pentonet is a standalone range of industrial grade, multi-band
                        router solutions providing best in class connectivity at speeds of up to 600Mbps over extended
                        ranges global bands from 4G, 3G and 2G standard cellular networks.</p>
                <p>It includes enhanced connectivity and security features to
                        ensure peace of mind for our customers. It also comes with
                        support in the Pentonet Portal which allows for
                        direct management and monitoring of the system.</p>
                <p>Systems built to be plug and play with PoE for LAN side
                        connectivity and all prepared prior to shipping.</p>
                <FleetConnexElementTextTitle variant={"h6"}>SUPPORTED BANDS</FleetConnexElementTextTitle>
                <ul>
                    <li>
                        <p>4G (LTE-FDD): B1 (2100 MHz), B2 (1900 MHz), B3 (1800 MHz), B4 (1700 MHz), B5 (850 MHz),
                            B7 (2600 MHz), B8 (900 MHz), B12 (700 MHz), B13 (1800 MHz), B18 (850 MHz), B19 (800
                            MHz), B20 (800 MHz), B25 (1900 MHz), B26 (850 MHz), B28 (700 MHz)</p>
                    </li>
                    <li>
                        <p>4G (LTE-TDD): B38 (2600 MHz), B39 (1900 MHz), B40 (2300 MHz), B41 (2500 MHz)</p>
                    </li>
                    <li>
                        <p>3G: B1 (2100 MHz), B2 (1900 MHz), B4 (1700 MHz), B5 (850 MHz), B6 (800 MHz), B8 (900
                            MHz), B19 (800 MHz)</p>
                    </li>
                </ul>
            </FleetConnexElementText>}
            {window.innerWidth < 768 &&  <Box sx={{
                height: "70vh",
                background: `url(${fleetConnex2}) no-repeat 100% center`,
                backgroundSize: "cover",
            }}/>}
            <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>WHAT&apos;S IN THE BOX?</FleetConnexElementTextTitle>
                <p>As standard, the FleetConnex devices are supplied with*
                    integral antennas (2 for the FleetConnex, 4 for the FleetConnex Pro). As an option they can be supplied with an*
                    Antenna Adaptor Plate allowing the antennas to be*
                    mounted separately, for example higher up the mast to*
                    provide enhanced range connectivity.</p>
                <FleetConnexElementTextTitle variant={"h6"}>
                    FLEETCONNEX FAMILY
                </FleetConnexElementTextTitle>
                <ul>
                    <li>FleetConnex</li>
                    <li>FleetConnex Pro</li>
                </ul>
                <p>The FleetConnex is supplied as standard to connect directly to the ship&lsquo;s network. As an option
                    it can be supplied
                    with an IDU that bonds the ethernet connections increasing the data throughputs. The FleetConnex Pro is
                    supplied with the IDU as standard, bonding up to 6 ethernet connections. The FleetConnect Pro
                    can also be
                    supplied with an optional GPS Location service.</p>
            </FleetConnexElementText>
        </FleetConnexElementSecondContainer>
    );
};

export default FleetConnexElementSecond;