import React from "react";
import {StarlinkCompanionElementSecondConnect1Container,
    StarlinkCompanionElementSecondConnectArrows,
    StarlinkCompanionElementSecondConnectLines} from "./Connect1";


const Connect2 = ():JSX.Element => {
    return (
        <>
            {window.innerWidth < 756 ? <StarlinkCompanionElementSecondConnectArrows>↓↓↓</StarlinkCompanionElementSecondConnectArrows> : <StarlinkCompanionElementSecondConnect1Container
                sx={{
                    transform: "rotateY(180deg)"
                }}
            >
                <StarlinkCompanionElementSecondConnectLines>
                    <p>---------------</p>
                    <p>------------</p>
                    <p>------------------------</p>
                    <p>------------</p>
                    <p>---------</p>
                    <p>---------------------------</p>
                </StarlinkCompanionElementSecondConnectLines>
            </StarlinkCompanionElementSecondConnect1Container>}
        </>
    );
};

export default Connect2;