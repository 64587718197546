import React from "react";
import {StoryInterface} from "../../interfaces/StoryInterface";
import {Box, Button, styled} from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {FleetConnexElementTextTitle} from "../FleetConnex/FleetConnexElementFirst";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {dataFormatter} from "../../helpers/dataFormatter";

interface SelectedStoryInterface {
    selectedStory: StoryInterface;
    onBack():void;
}

const SelectedStoryContainer = styled(Box)({
    marginTop: "-120px",
});

const SelectedStoryContainerImage = styled(Box)({
    height: "45vh",
    width: "100%",
});

const SelectedStoryButtonBackContainer = styled(Box)({
    position: "fixed",
    bottom: "100px",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex:1,
});

const SelectedStoryButtonBack = styled(Button)({
    color: "#FFFFFF",
    textShadow: "#000000 1px 0 10px",
    fontSize: "1.1rem",
    background: "rgba(33,33,33,.6)",
    transition: "background .3s ease",
    "&:hover":{
        background: "rgba(33,33,33,.8)",
    },
    "@media(max-width: 768px)":{
        fontSize: "0.75rem",
    }
});

const SelectedStoryTextContainer = styled(Box)({
    padding: "20px",
    boxSizing: "border-box",
    background: "rgba(25,26,26, .6)",
    backdropFilter: "blur(40px)",
    "& p":{
        color: "#FFFFFF",
        whiteSpace: "pre-line",
        fontSize: "20px",
        fontWeight: 400
    },
});

const SelectedStoryButtonLinkedIn = styled(Button)({
    display: "flex",
    gap: "10px",
    color: "rgba(33,33,33,1)",
    fontSize: "1.1rem",
    background: "#FFFFFF",
    transition: "background .3s ease",
    "&:hover":{
        background: "silver",
    },
    "@media(max-width: 768px)":{
        fontSize: "0.75rem",
    }
});

const SelectedStoryTimeCreatedBlock = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p":{
        color: "#FFFFFF",
        whiteSpace: "pre-line",
        fontSize: "14px",
        fontWeight: 400,
        "@media(max-width: 768px)":{
            fontSize: "10px",
        }
    },
    "@media(max-width: 768px)":{
        flexDirection: "column-reverse"
    }
});

const SelectedStory = ({selectedStory, onBack}: SelectedStoryInterface): JSX.Element => {
    
    const handleOpen = ():void => {
        window.open(selectedStory.attributes.linkedInLink);
    };
    
    const renderLindkedInLink = ():React.ReactNode => {
        if(selectedStory.attributes.linkedInLink && selectedStory.attributes.linkedInLink.length){
            return (
                <SelectedStoryButtonLinkedIn onClick={handleOpen}>
                    <LinkedInIcon htmlColor={"rgba(33,33,33,1)"}/>
                    View on LinkedIn
                    <KeyboardArrowRightIcon htmlColor={"rgba(33,33,33,1)"}/>
                </SelectedStoryButtonLinkedIn>
            );
        }
        return null;
    };
    
    return (
        <SelectedStoryContainer
            sx={{
                background: `url(${selectedStory.attributes.picture.data.attributes.url}) no-repeat center 0`,
                backgroundSize: "contain",
            }}
        >
            <SelectedStoryContainerImage/>
            <SelectedStoryButtonBackContainer>
                <SelectedStoryButtonBack onClick={onBack}>
                    <KeyboardArrowLeftOutlinedIcon htmlColor={"#FFFFFF"}/>
                    Back
                </SelectedStoryButtonBack>
            </SelectedStoryButtonBackContainer>
            <SelectedStoryTextContainer>
                <FleetConnexElementTextTitle variant={"h6"}>{selectedStory.attributes.title}</FleetConnexElementTextTitle>
                <p>{selectedStory.attributes.description}</p>
                <br/>
                <br/>
                <p>{selectedStory.attributes.subtitle}</p>
                <SelectedStoryTimeCreatedBlock>
                    <Box>
                        <p>{`Created At: ${dataFormatter(selectedStory.attributes.createdAt)}`}</p>
                        <p>{`Last Updated: ${dataFormatter(selectedStory.attributes.updatedAt)}`}</p>
                    </Box>
                    {renderLindkedInLink()}
                </SelectedStoryTimeCreatedBlock>
            </SelectedStoryTextContainer>
        </SelectedStoryContainer>
    );
};

export default SelectedStory;