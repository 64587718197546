import React from "react";
import {Box, styled} from "@mui/material";
import CargoShipSvgIcon from "../../assets/CargoShipSvgIcon";
import {FleetConnexIcon} from "../../assets/FleetConnexIcon";

const borderAnimation = {
    "0%": {
        padding: "0px"
    },
    "100%": {
        left: "-200px",
        top: "-200px",
        padding: "200px"
    },
};

const StoriesElementsLoaderContainer = styled(Box)({
    display: "flex",
    height: "calc(100vh - 240px)",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
});

const StoriesElementsLoaderElementAnimated = styled(Box)({
    position: "relative",
    animation: "float 2s linear infinite",
    "@keyframes float":{
        "0%": {
            transform: "translate(0,  0px)" 
        },
        "65%": {
            transform: "translate(0px, 25px)",
        },
        "100%": {
            transform: "translate(0,  0px)"
        },
    },
});

const StoriesElementsLoaderElementAnimatedBox = styled(Box)({
    position: "relative",
    animation: "waves 2s linear infinite",
    "@keyframes waves":{
        "0%": {
            transform: "rotate(0deg)"
        },
        "45%": {
            transform: "rotate(-2deg)",
        },
        "85%": {
            transform: "rotate(2deg)",
        },
        "100%": {
            transform: "rotate(0deg)"
        },
    }
});

const StoriesElementsLoaderElementAnimatedCircles = styled(Box)({
    position: "absolute",
    top: "2vw",
    left: "6.5vw",
    width: "5vw",
    height: "5vw",
    "& div:first-of-type":{
        width: "5vw",
    },
}); 

const FleetConnexIconAnimationCircle = styled(Box)({
    position: "absolute",
    left: "10%",
    top: "20%",
    height: "10px",
    width: "10px",
    aspectRatio: "1/1",
    borderRadius: "100%",
    border: "2px solid rgba(33,33,33,1)",
    zIndex: -1,
});

const FleetConnexIconAnimationCircle1 = styled(FleetConnexIconAnimationCircle)({
    animation: "border 1.5s linear infinite",
    "@keyframes border": borderAnimation
});

const FleetConnexIconAnimationCircle2 = styled(FleetConnexIconAnimationCircle)({
    animation: "border 1.5s .5s linear infinite",
    "@keyframes border": borderAnimation
});
const FleetConnexIconAnimationCircle3 = styled(FleetConnexIconAnimationCircle)({
    animation: "border 1.5s 1s linear infinite",
    "@keyframes border": borderAnimation
});

const StoriesElementsLoader = ():JSX.Element => {
    return <StoriesElementsLoaderContainer>
        <StoriesElementsLoaderElementAnimatedBox>
            <StoriesElementsLoaderElementAnimated>
                <CargoShipSvgIcon width={"60vw"} height={"auto"} fillShip={"#474747"} fillBoxes={"#666666"}/>
                <StoriesElementsLoaderElementAnimatedCircles>
                    <FleetConnexIcon/>
                    <FleetConnexIconAnimationCircle1/>
                    <FleetConnexIconAnimationCircle2/>
                    <FleetConnexIconAnimationCircle3/>
                </StoriesElementsLoaderElementAnimatedCircles>
            </StoriesElementsLoaderElementAnimated>
        </StoriesElementsLoaderElementAnimatedBox>
    </StoriesElementsLoaderContainer>;
};

export default StoriesElementsLoader;