import React from "react";
import {Box, styled, Typography} from "@mui/material";
import fleetConnex3 from "../../assets/fleetConnex3.png";
import fleetConnex2 from "../../assets/fleetConnex2.png";

export const FleetConnexElementContainer = styled(Box)({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    "@media(max-width: 768px)":{
        display: "block",
    }
});

export const FleetConnexElementText = styled(Box)({
    padding: "20px 30px",
    boxSizing: "border-box",
    "& p":{
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: 400
    },
    "& li":{
        color: "#FFFFFF",
        fontSize: "22px",
        fontWeight: 600,
        textTransform: "uppercase",
    }
});

export const FleetConnexElementTextTitle = styled(Typography)({
    color: "#fafafa",
    fontSize: "40px",
    fontWeight: 700,
});

const FleetConnexElementFirst = ():JSX.Element => {
    return (
        <FleetConnexElementContainer>
            <Box sx={{
                height: window.innerWidth < 768 ? "30vh" : "70vh",
                background: `url(${fleetConnex3}) no-repeat center`,
                backgroundSize: "contain",
            }}/>
            {window.innerWidth >= 768 &&  <Box sx={{
                height: "70vh",
                background: `url(${fleetConnex2}) no-repeat 100% center`,
                backgroundSize: "cover",
            }}/>}
            {window.innerWidth < 768 &&  <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>
                    FLEETCONNEX INTRO
                </FleetConnexElementTextTitle>
                <p>The FleetConnex family from Pentonet is a standalone range of industrial grade, multi-band
                    router solutions providing best in class connectivity at speeds of up to 600Mbps over extended
                    ranges global bands from 4G, 3G and 2G standard cellular networks.</p>
                <p>It includes enhanced connectivity and security features to
                    ensure peace of mind for our customers. It also comes with
                    support in the Pentonet Portal which allows for
                    direct management and monitoring of the system.</p>
                <p>Systems built to be plug and play with PoE for LAN side
                    connectivity and all prepared prior to shipping.</p>
                <FleetConnexElementTextTitle variant={"h6"}>SUPPORTED BANDS</FleetConnexElementTextTitle>
                <ul>
                    <li>
                        <p>4G (LTE-FDD): B1 (2100 MHz), B2 (1900 MHz), B3 (1800 MHz), B4 (1700 MHz), B5 (850 MHz),
                            B7 (2600 MHz), B8 (900 MHz), B12 (700 MHz), B13 (1800 MHz), B18 (850 MHz), B19 (800
                            MHz), B20 (800 MHz), B25 (1900 MHz), B26 (850 MHz), B28 (700 MHz)</p>
                    </li>
                    <li>
                        <p>4G (LTE-TDD): B38 (2600 MHz), B39 (1900 MHz), B40 (2300 MHz), B41 (2500 MHz)</p>
                    </li>
                    <li>
                        <p>3G: B1 (2100 MHz), B2 (1900 MHz), B4 (1700 MHz), B5 (850 MHz), B6 (800 MHz), B8 (900
                            MHz), B19 (800 MHz)</p>
                    </li>
                </ul>
            </FleetConnexElementText>}
        </FleetConnexElementContainer>
    );
};

export default FleetConnexElementFirst;