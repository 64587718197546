import React from "react";
import {Box, styled} from "@mui/material";
import {StoryInterface} from "../../interfaces/StoryInterface";

interface StoriesElementInterface {
    el: StoryInterface;
    handleSelect(el: StoryInterface):void;
}

const StoriesItemContainer = styled(Box)({
    cursor: "pointer",
    aspectRatio: "1/1",
    marginTop: "4.27rem",
    transition: "transform .2s ease,filter .2s ease",
    borderRadius: "8px",
    display: "flex",
    alignItems: "end",
    padding: "10px",
    boxSizing: "border-box",
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: 400,
    "& p":{
        transition: "opacity .2s ease, background .2s .05s ease",
        opacity: 0.1,
        margin: 0,
        padding: "5px",
        "&:last-of-type":{
            fontSize: "14px",
        },
        "@media(max-width: 768px)":{
            opacity: 1,
        },
    },
    "&:hover":{
        transform: "scale(1.05)",
        filter: "brightness(70%)",
        "& p":{
            background: "rgba(33,33,33,.8)",
            opacity: 1,
        },
    },
    "@media(max-width: 768px)":{
        overflow: "hidden",
        fontSize: "12px",
        "& p": {
            "&:last-of-type": {
                fontSize: "10px",
            },
        },
        marginTop: "1.25rem",
    },
});

const StoriesItem = ({el, handleSelect}: StoriesElementInterface):JSX.Element => {
    return (
        <StoriesItemContainer
            key={"story-" + el.id}
            onClick={() => handleSelect(el)}
            sx={{
                background: `url(${el.attributes.picture.data.attributes.url}) no-repeat center`,
                backgroundSize: "cover",
            }}
        >
            <Box>
                <p>{el.attributes.title}</p>
                <p>{el.attributes.subtitle}</p>
            </Box>
        </StoriesItemContainer>
    );
};

export default StoriesItem