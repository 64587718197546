import React from "react";
import sailorTalkMainVideoPoster from "../../assets/sailorTalkMainVideoPoster.png";
import {StatementText, StatementTitle} from "../Main/Statement";
import {styled} from "@mui/material";

const SailorTalkStatementContainer = styled("section")({
    position: "relative",
    width: "100%",
    height: "fit-content",
    "@media(max-width: 768px)":{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        "& video":{
            height: "100%",
            width: "auto",
            marginLeft: "-130%"
        }
    },
    "@media(max-height: 400px)":{
        "& video": {
            marginLeft: "0"
        }
    }
});

const SailorTalkStatement = ():JSX.Element => {
    return (
        <SailorTalkStatementContainer>
            <video width={"100%"} autoPlay muted loop preload="auto" playsInline poster={sailorTalkMainVideoPoster}>
                <source src={require("../../assets/sailorTalkMainVideo.mp4")}/>
            </video>
            <StatementText>
                <StatementTitle>
                    Global roaming service aimed at seafarers
                </StatementTitle>
            </StatementText>
        </SailorTalkStatementContainer>
    );
};

export default SailorTalkStatement;