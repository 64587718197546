import React from "react";
import {styled, Box} from "@mui/material";
import fleetConnexMainVideoPoster from "../../assets/fleetConnexMainVideoPoster.png";
import {StatementText, StatementTitle} from "../Main/Statement";
import PublicIcon from "@mui/icons-material/Public";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import {FleetConnexStatementButtonContainer, FleetConnexStatementLink} from "../FleetConnex/FleetConnexStatement";
import {useNavigate} from "react-router-dom";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const StarlinkCompanionStatementContainer = styled("section")({
    position: "relative",
    width: "100%",
    height: "fit-content",
    "@media(max-width: 768px)":{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        "& video":{
            height: "100%",
            width: "auto",
            marginLeft: "-130%"
        }
    },
    "@media(max-height: 400px)":{
        "& video": {
            marginLeft: "0"
        }
    }
});

export const StarlinkCompanionStatementButtonContainer = styled(Box)({
    position: "absolute",
    bottom: "40px",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
});

const StarlinkCompanionStatement = ():JSX.Element => {

    const navigate = useNavigate();

    return (
        <StarlinkCompanionStatementContainer>
            <video width={"100%"} autoPlay muted loop preload="auto" playsInline poster={fleetConnexMainVideoPoster}>
                <source src={require("../../assets/mainPageVideo.mp4")}/>
            </video>
            <StatementText>
                <StatementTitle>
                    Starlink Companion
                </StatementTitle>
            </StatementText>
            <FleetConnexStatementButtonContainer>
                <FleetConnexStatementLink onClick={() => navigate("/sdWanVideo")}>
                    <OndemandVideoIcon htmlColor={"#FFFFFF"} sx={{
                        marginRight: "10px",
                    }} />
                    View SDWAN Video
                    <KeyboardArrowRightOutlinedIcon htmlColor={"#FFFFFF"}/>
                </FleetConnexStatementLink>
                <a href={"https://www.starlink.com/map"} target="_blank" rel="noreferrer">
                    <FleetConnexStatementLink>
                        <PublicIcon htmlColor={"#FFFFFF"} sx={{
                            marginRight: "10px",
                        }} />
                    View starlink availability
                        <KeyboardArrowRightOutlinedIcon htmlColor={"#FFFFFF"}/>
                    </FleetConnexStatementLink>
                </a>
                <FleetConnexStatementLink onClick={() => navigate("/connectivityMap")}>
                    <PublicIcon htmlColor={"#FFFFFF"} sx={{
                        marginRight: "10px",
                    }} />
                    View FleetConnex availability
                    <KeyboardArrowRightOutlinedIcon htmlColor={"#FFFFFF"}/>
                </FleetConnexStatementLink>
            </FleetConnexStatementButtonContainer>

        </StarlinkCompanionStatementContainer>
    );
};

export default StarlinkCompanionStatement;