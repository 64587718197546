import React from "react";
import {Box, styled} from "@mui/material";

export const StarlinkCompanionElementSecondConnect1Container = styled(Box)({
    position: "relative",
    "&:before":{
        content: "' '",
        position: "absolute",
        top: 0,
        left: 0,
        height: "30%",
        width: "100%",
        borderRadius: "0 0 0 100%",
        borderLeft: "4px solid #FFFFFF",
        borderBottom: "2px solid #FFFFFF",
    },
    "&:after":{
        content: "' '",
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "30%",
        width: "100%",
        borderRadius: "100% 0 0 0",
        borderLeft: "4px solid #FFFFFF",
        borderTop: "2px solid #FFFFFF",
    },
});

export const StarlinkCompanionElementSecondConnectLines = styled(Box)({
    position: "absolute",
    width: "100%",
    overflow: "hidden",
    top: "40%",
    height: "20%",
    textAlign: "right"
});

export const StarlinkCompanionElementSecondConnectArrows = styled("p")({
    textAlign: "center",
    fontSize: "28px !important",
    lineHeight: 3,
});


const Connect1 = ():JSX.Element => {
    return (
        <>
            {window.innerWidth < 756 ? <StarlinkCompanionElementSecondConnectArrows>↓↓↓</StarlinkCompanionElementSecondConnectArrows> : <StarlinkCompanionElementSecondConnect1Container>
           
                <StarlinkCompanionElementSecondConnectLines>
                    <p>------------------------</p>
                    <p>------------------</p>
                    <p>---------</p>
                    <p>------------------------</p>
                    <p>------------------</p>
                    <p>------------------------</p>
                </StarlinkCompanionElementSecondConnectLines>
            </StarlinkCompanionElementSecondConnect1Container>}
        </>
    );
};

export default Connect1;