import React from "react";
import "./App.css";
import Pages from "./components/Pages";

function App() {
    return (
        <Pages/>
    );
}

export default App;
