import React from "react";
import {Box, styled, Typography} from "@mui/material";

interface LegalTitleInterface {
    title: string;
    lastUpdated: string;
}


const LegalTitleContainer = styled("section")({
    height: "40vh",
    width: "100%",
    background: "url('https://pentonet.com/static/7b2c5a597f0a42f43f5eaecf89f47ceb/6e8dd/legal.webp') center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10%",
    boxSizing: "border-box",
    color: "#FFFFFF",
    "& h3":{
        fontWeight: 700,
        textTransform: "uppercase"
    }
});

const LegalTitle = ({title, lastUpdated}: LegalTitleInterface):JSX.Element => {
    return (
        <LegalTitleContainer>
            <Box>
                <Typography variant={"h3"}>{title}</Typography>
                <Typography variant={"h6"}>{lastUpdated}</Typography>
            </Box>
        </LegalTitleContainer>
    );
};

export default LegalTitle;