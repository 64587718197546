import React from "react";
import {Box, Button, styled} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";


// eslint-disable-next-line @typescript-eslint/no-var-requires
const file1 = require("../../files/Pentonet_Datasheet_Edge_Fit_202310415.pdf");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const file2 = require("../../files/Pentonet_Datasheet_Edge_Pro_20230415.pdf");

const PDFButtonsContainer = styled(Box)({
    width: "100%",
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    position: "absolute",
    bottom : "40px",
    left: 0,
    padding: "0 10px",
    boxSizing: "border-box"
});

const PDFButtonEl = styled(Button)({
    width: "100%",
    color: "#FFFFFF",
    textShadow: "#000000 1px 0 10px",
    fontSize: "0.75rem",
    background: "rgba(33,33,33,.8)",
    backdropFilter: "contrast(.7)",
    transition: "backdrop-filter .3s ease",
    "&:hover":{
        backdropFilter: "contrast(.9)",
    },
});

const PDFButtons = ():JSX.Element => {

    const handleDownloadEdgeFit = ():void => {
        const anchor = document.createElement("a");
        anchor.href = file1;
        anchor.download = "EdgeFit.pdf";
        anchor.click();
    };

    const handleDownloadEdgePro = ():void => {
        const anchor = document.createElement("a");
        anchor.href = file2;
        anchor.download = "EdgePro.pdf";
        anchor.click();
    };


    return (
        <PDFButtonsContainer>
            <PDFButtonEl onClick={handleDownloadEdgeFit}>
                <ListAltOutlinedIcon htmlColor={"#FFFFFF"} sx={{
                    marginRight: "10px",
                }} />
                Edge Fit
                <DownloadIcon htmlColor={"#FFFFFF"} sx={{
                    marginLeft: "auto",
                }} />
            </PDFButtonEl>
            <PDFButtonEl onClick={handleDownloadEdgePro}>
                <ListAltOutlinedIcon htmlColor={"#FFFFFF"} sx={{
                    marginRight: "10px",
                }} />
                Edge Pro
                <DownloadIcon htmlColor={"#FFFFFF"} sx={{
                    marginLeft: "auto",
                }} />
            </PDFButtonEl>
        </PDFButtonsContainer>
    );
};

export default PDFButtons;