import React, {useEffect, useState} from "react";
import {Box, IconButton, Menu, styled, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router-dom";
import logo from "../../assets/LogoDark.png";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HeaderTooltip from "./HeaderTooltip";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {FleetConnexIcon} from "../../assets/FleetConnexIcon";
import SimCardIcon from "@mui/icons-material/SimCard";
import PhoneIcon from "@mui/icons-material/Phone";
import ReactGA from "react-ga";
import {StarlinkCompanionIcon} from "../../assets/StarlinkCompanionIcon";

const HeaderContainer = styled("header")({
    position: "fixed",
    background: "rgba(33,33,33,.8)",
    backdropFilter: "blur(15)",
    height: "100px",
    width: "100%",
    display: "flex",
    padding: "0 70px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex:1,
    "& img":{
        height: "100px"
    },
    "@media(max-width: 768px)":{
        padding: "0 10px",
    }
});

const HeaderContainerEl = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "40px",
    "& a":{
        textDecoration: "none",  
    },
    "@media(max-width: 768px)":{
        flexDirection: "column",
        alignItems: "start",
    }
});

const LogoLink = styled(NavLink)({
    marginRight: "50%"
});

const Header = ():JSX.Element => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location.pathname]);
    
    const handleClose = ():void => {
        setAnchorEl(null);
    };
    
    const renderMobileText = (text: string):React.ReactNode => {
        if(window.innerWidth > 768){
            return null;
        }
        return (
            <Typography sx={{
                padding: 0,
                fontSize: "18px",
                fontWeight: "500",
                color: "#FFFFFF",
                marginLeft: "10px",
            }}>{text}</Typography>
        );
    };

    const renderMenu = ():React.ReactNode => {
        return (
            <HeaderContainerEl onClick={handleClose}>
                {(location.pathname !== "/starlinkCompanion" && location.pathname !== "/") && <NavLink to={"/starlinkCompanion"}>
                    <HeaderTooltip text={"Starlink Companion"}>
                        <StarlinkCompanionIcon/>
                        {renderMobileText("Starlink Companion")}
                    </HeaderTooltip>
                </NavLink>}
                {(location.pathname !== "/fleetConnex" && location.pathname !== "/") && <NavLink to={"/fleetConnex"}>
                    <HeaderTooltip text={"FleetConnex"}>
                        <FleetConnexIcon/>
                        {renderMobileText("FleetConnex")}
                    </HeaderTooltip>
                </NavLink>}
                {(location.pathname !== "/sailorTalk" && location.pathname !== "/") &&  <NavLink to={"/sailorTalk"}>
                    <HeaderTooltip text={"SailorTalk"}>
                        <SimCardIcon htmlColor={"#FFFFFF"} sx={{
                            transform: window.innerWidth > 768 ? "scale(1.5)" : ""
                        }} />
                        {renderMobileText("SailorTalk")}
                    </HeaderTooltip>
                </NavLink>}
                {(location.pathname !== "/shipTalk" && location.pathname !== "/") &&  <NavLink to={"/shipTalk"}>
                    <HeaderTooltip text={"ShipTalk"}>
                        <PhoneIcon htmlColor={"#FFFFFF"} sx={{
                            transform: window.innerWidth > 768 ? "scale(1.5)" : ""
                        }} />
                        {renderMobileText("ShipTalk")}
                    </HeaderTooltip>
                </NavLink>}
                <NavLink to={"/partnership"}>
                    <HeaderTooltip text={"Partnership"}>
                        <HandshakeIcon htmlColor={"#FFFFFF"} sx={{
                            transform: window.innerWidth > 768 ? "scale(1.5)" : ""
                        }} />
                        {renderMobileText("Partnership")}
                    </HeaderTooltip>
                </NavLink>
                <NavLink to={"/stories"}>
                    <HeaderTooltip text={"Stories"}>
                        <PeopleIcon htmlColor={"#FFFFFF"} sx={{
                            transform: window.innerWidth > 768 ? "scale(1.5)" : ""
                        }} />
                        {renderMobileText("Stories")}
                    </HeaderTooltip>
                </NavLink>
                <NavLink to={"/shop"}>
                    <HeaderTooltip text={"Shop"}>
                        <ShoppingCartIcon htmlColor={"#FFFFFF"} sx={{
                            transform: window.innerWidth > 768 ? "scale(1.5)" : ""
                        }} />
                        {renderMobileText("Shop")}
                    </HeaderTooltip>
                </NavLink>
            </HeaderContainerEl>
        );
    };

    return (
        <HeaderContainer>
            <HeaderContainerEl>
                <LogoLink to={"/"}>
                    <img alt={"pentonet_logo"} src={logo}/>
                </LogoLink>
            </HeaderContainerEl>
            {window.innerWidth > 768 && renderMenu()}
            {window.innerWidth <= 768 && <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}><MenuOutlinedIcon htmlColor={"#FFFFFF"}/></IconButton>}
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}
                sx={{
                    "& .MuiMenu-paper":{
                        background: "rgba(33,33,33,.8)",
                        backdropFilter: "blur(15)",
                        padding: "0 10px",
                    },
                }}
            >
                {renderMenu()}
            </Menu>
        </HeaderContainer>
    );
};

export default Header;