import {BASE_URL} from "../../config/apiConfig";

export const getStories = ():Promise<Response> => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const options = {
        method: "GET",
        headers,
    };
    return fetch(`${BASE_URL}/api/stories?populate=*`, options as any);
};