import React from "react";
import {Box} from "@mui/material";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Header from "./Header/Header";
import Main from "./Main/Main";
import Footer from "./Footer/Footer";
import Partnership from "./Partnership/Partnership";
import ConnectivityMap from "./ConnectivityMap/ConnectivityMap";
import Shop from "./Shop/Shop";
import SailorTalk from "./SailorTalk/SailorTalk";
import ShipTalk from "./ShipTalk/ShipTalk";
import FleetConnex from "./FleetConnex/FleetConnex";
import CookiesPolicy from "./Legal/CookiesPolicy";
import PrivacyPolicy from "./Legal/PrivacyPolicy";
import TermsAndConditions from "./Legal/TermsAndConditions";
import ErrorPage from "./ErrorPage/ErrorPage";
import Stories from "./Stories/Stories";
import StarlinkCompanion from "./StarlinkCompanion/StarlinkCompanion";
import SdWanVideo from "./SdWanVideo/SdWanVideo";

const Pages = ():JSX.Element => {

    return (
        <BrowserRouter>
            <Box height={"100%"} sx={{
                width: "100%",
                margin: "auto",
            }}>
                <Header/>
                <Box display={"flex"} height={"100%"} sx={{
                    width: "100%",
                    height: "fit-content",
                    position: "absolute",
                    overscrollBehavior: "auto",
                    background: "#191A1A",
                    flexDirection: "column",
                }}
                >
                    <Routes>
                        <Route
                            key={"main"}
                            path={"/"}
                            element={<Main/>}
                        />
                        <Route
                            key={"partnership"}
                            path={"/partnership"}
                            element={<Partnership/>}
                        />
                        <Route
                            key={"stories"}
                            path={"/stories"}
                            element={<Stories/>}
                        />
                        <Route
                            key={"map"}
                            path={"/connectivityMap"}
                            element={<ConnectivityMap/>}
                        />
                        <Route
                            key={"shop"}
                            path={"/shop"}
                            element={<Shop/>}
                        />
                        <Route
                            key={"sailorTalk"}
                            path={"/sailorTalk"}
                            element={<SailorTalk/>}
                        />
                        <Route
                            key={"fleetConnex"}
                            path={"/fleetConnex"}
                            element={<FleetConnex/>}
                        />
                        <Route
                            key={"shipTalk"}
                            path={"/shipTalk"}
                            element={<ShipTalk/>}
                        />
                        <Route
                            key={"starlinkCompanion"}
                            path={"/starlinkCompanion"}
                            element={<StarlinkCompanion/>}
                        />
                        <Route
                            key={"terms"}
                            path={"/legal/terms"}
                            element={
                                <TermsAndConditions/>}
                        />
                        
                        <Route
                            key={"privacy-policy"}
                            path={"/legal/privacy-policy"}
                            element={<PrivacyPolicy/>}
                        />
                        <Route
                            key={"sdWanVideo"}
                            path={"/sdWanVideo"}
                            element={<SdWanVideo/>}
                        />

                        <Route
                            key={"cookies"}
                            path={"/legal/cookies"}
                            element={<CookiesPolicy/>}
                        />
                        <Route
                            path="*"
                            key={"error"}
                            element={<ErrorPage/>}
                        />
                    </Routes>
                    <Footer/>
                </Box>
            </Box>
        </BrowserRouter>
    );
};

export default Pages;