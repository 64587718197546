import React from "react";
import {styled} from "@mui/material";
import Contacts from "../Main/Contacts";
import StarlinkCompanionStatement from "./StarlinkCompanionStatement";
import StarlinkCompanionElementFirst from "./StarlinkCompanionElementFirst";
import StarlinkCompanionElementSecond from "./StarlinkCompanionElementSecond/StarlinkComanionElementSecond";

const StarlinkCompanionContainer = styled("main")({
    padding:0,
    marginBottom: "40px",
    "& *":{
        "& li":{
            textTransform: "none",
        }
    }
});
const StarlinkCompanion = ():JSX.Element => {
    return (
        <StarlinkCompanionContainer>
            <StarlinkCompanionStatement/>
            <StarlinkCompanionElementFirst/>
            <StarlinkCompanionElementSecond/>
            <Contacts/>
        </StarlinkCompanionContainer>
    );
};

export default StarlinkCompanion;