import React from "react";
import {Box, styled} from "@mui/material";
import {FleetConnexElementText,
    FleetConnexElementTextTitle} from "../FleetConnex/FleetConnexElementFirst";

const SailorTalkElementThirdContainer = styled(Box)({
    display: "block",
    height: "auto",
});

const SailorTalkElementThird = ():JSX.Element => {
    return (
        <SailorTalkElementThirdContainer>
            <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>
                    CHOOSE HOW YOU WANT TO BE BILLED
                </FleetConnexElementTextTitle>
                <p>Pay as you go: You are charged per consumed unit (talking minute/sent SMS/data MBs).</p>
                <p>Data bundles and special data packages.Dial a package code according to your chosen plan
                    the selected plan&apos;s cost is instantly deducted from your sim&apos;s balance,
                    you then have a certain volume and time limit (according to your chosen plan) to consume.</p>
            </FleetConnexElementText>
        </SailorTalkElementThirdContainer>
    );
};

export default SailorTalkElementThird;