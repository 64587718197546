import React from "react";
import {Box, styled} from "@mui/material";
import Products from "./Products";
import Statement from "./Statement";
import Contacts from "./Contacts";
import fleetConnex3 from "../../assets/fleetConnex3.png";

const MainContainer = styled("main")({
    padding:0,
    marginBottom: "40px",
});

const Main = ():JSX.Element => {
    return (
        <MainContainer>
            <Statement/>
            <Products/>
            <Contacts/>
            <Box sx={{
                height:0,
                width: 0,
                overflow: "hidden"
            }}>
                <picture>
                    <source type={"image/webp"} sizes="(min-width: 366px) 366px, 10vw" srcSet={fleetConnex3}/>
                    <img alt={"fleetConnexImage"} role={"presentation"} aria-hidden={true} src={fleetConnex3}/>
                </picture>
            </Box>
        </MainContainer>
    );
};

export default Main;