import React from "react";
import {Box, Button, styled} from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import {useNavigate} from "react-router-dom";
import {randomIntFromInterval} from "../../helpers/randomIntFromInterval";

const ErrorPageContainer = styled("main")({
    height: "calc(100vh - 80px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
});

const glichAnimSkew = () => {
    const array = new Array(10);
    for(let i = 0; i<array.length; i++){
        array[`${i * 10 + "%"}` as any] = {
            transform: `skew(${randomIntFromInterval(-5, 5)}deg)`
        };
    }
    return {
        ...array
    };
};

const glichAnim1 = () => {
    const array = new Array(20);
    for(let i = 0; i<array.length; i++){
        array[`${i * 5 + "%"}` as any] = {
            clip: `rect(3px, 9999px, ${randomIntFromInterval(0, 15)}vw, 0)`,
            transform: `skew(${randomIntFromInterval(-20, 20)/10}deg)`
        };
    }
    return {
        ...array
    };
};

const glichAnim2 = () => {
    const array = new Array(20);
    for(let i = 0; i<array.length; i++){
        array[`${i * 5 + "%"}` as any] = {
            clip: `rect(3px, 9999px, ${randomIntFromInterval(0, 5)}vw, 0)`,
            transform: `skew(${randomIntFromInterval(-20, 20)/10}deg)`
        };
    }
    return {
        ...array
    };
};

const ErrorPageText = styled(Box)({
    textAlign: "center",
    animation: "glitch-skew 1.5s infinite linear alternate-reverse",
    "& h1":{
        position: "relative",
        margin: "0 5px",
        color: "#FFFFFF",
        fontWeight: 700,
        "&:first-of-type":{
            fontSize: "15vw",
            "&:before": {
                content: "'404'",
                animation: "glitch-anim 6s infinite linear alternate-reverse"
            },
            "&:after": {
                content: "'404'",
                animation: "glitch-anim2 6s infinite linear alternate-reverse"
            }
        },
        fontSize: "5vw",
        "&:before":{
            content: "'OPPS! PAGE NOT FOUND'",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            left: "2px",
            textShadow: "-2px 0 #ff00c1",
            clip: "rect(44px, 100vw, 56px, 0)",
            animation: "glitch-anim3 6s infinite linear alternate-reverse"
        },
        "&:after": {
            content: "'OPPS! PAGE NOT FOUND'",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            left: "-2px",
            textShadow: "-2px 0 #ff00c1, 2px 2px #ff00c1",
            clip: "rect(44px, 100vw, 56px, 0)",
            animation: "glitch-anim4 6s infinite linear alternate-reverse"
        }
    },
    "@keyframes glitch-skew": glichAnimSkew(),
    "@keyframes glitch-anim": glichAnim1(),
    "@keyframes glitch-anim2": glichAnim1(),
    "@keyframes glitch-anim3": glichAnim2(),
    "@keyframes glitch-anim4": glichAnim2(),
});

const ErrorPageBackButtonContainer = styled(Box)({
    position: "absolute",
    bottom: "120px",
});

const ErrorPageBackButton = styled(Button)({
    color: "#FFFFFF",
    textShadow: "#000000 1px 0 10px",
    fontSize: "1.1rem",
    background: "rgba(33,33,33,.6)",
    transition: "background .3s ease",
    "&:hover":{
        background: "rgba(33,33,33,.8)",
    },
    "@media(max-width: 768px)":{
        fontSize: "0.75rem",
    }
});


const ErrorPage = ():JSX.Element => {
    const navigate = useNavigate();
    return (
        <ErrorPageContainer>
            <ErrorPageText>
                <h1>404</h1>
                <h1>OPPS! PAGE NOT FOUND</h1>
            </ErrorPageText>
            <ErrorPageBackButtonContainer>
                <ErrorPageBackButton onClick={() => navigate("/")}>
                    <KeyboardArrowLeftOutlinedIcon htmlColor={"#FFFFFF"}/>
                    GO HOME
                </ErrorPageBackButton>
            </ErrorPageBackButtonContainer>
        </ErrorPageContainer>
    );
};

export default ErrorPage;