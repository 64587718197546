import React from "react";
import {Box, styled, Typography} from "@mui/material";
import contactPicture1 from "../../assets/contactPicture1.png";
import contactPicture2 from "../../assets/contactPicture2.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import useAnalyticsEventTracker from "../../helpers/useAnalyticsEventTracker";

const ContactsContainer = styled("section")({
    marginTop: "100px",
    padding: "0 5%",
});

const ContactsTitle = styled(Typography)({
    textTransform: "uppercase",
    fontSize: "2.75rem",
    fontWeight: 700,
    color: "#FFFFFF",
    width: "100%",
    textAlign: "center",
    "@media(max-width: 768px)":{
        fontSize: "1.75rem",
    }
});

const ContactsElementsContainer = styled(Box)({
    display: "grid",
    gridTemplateColumns: "10fr 1fr 10fr",
    "& i":{
        height: "100%",
        width: "2px",
        margin: "auto",
        background: "rgba(33,33,33,.8)",
    },
    "@media(max-width: 768px)":{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr",
        rowsGap: "20px"
    }
});

const ContactsElement = styled(Box)({
    display: "grid",
    height: "100%",
    gridTemplateColumns: "2fr 3fr",
    columnGap: "20px",
    padding: "10px",
    boxSizing: "border-box",
    position: "relative",
    backdropFilter: "contrast(.7)",
    borderRadius: "0 30px 0 0",
});

const ContactsElementTitle = styled(Typography)({
    color: "#FFFFFF",
    fontSize: "40px",
    fontWeight: 700,
    "@media(max-width: 768px)":{
        fontSize: "20px",
    }
});

const ContactsElementUpperTitle = styled(Typography)({
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: 400,
    "@media(max-width: 768px)":{
        fontSize: "12px",
    }
});

const ContactsElementUnderTitle = styled(Typography)({
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 400
});

const ContactsLink = styled("a")({
    textDecoration: "none",
    transition: "background .3s ease",
    display: "flex",
    width: "fit-content",
    margin: "15px 0",
    "&:hover":{
        background: "rgba(255,255,255,.1)",
        color:"#FFFFFF",
        textDecoration: "none",    
    },
    "& p":{
        marginLeft: "5px"
    }
});

const ContactsElementPadding = styled(Box)({
    paddingTop: "40px"
});

const Contacts = ():JSX.Element => {
    const gaEventTracker = useAnalyticsEventTracker("click_to_contact");
    
    const renderContactEl = (picture: string, name: string,upperText: string, underText: string, phone: string, email: string, modify?: boolean, calendarLink?: string):React.ReactNode => {
        return (
            <ContactsElementPadding>
                <ContactsElement
                    sx={{
                        transform: modify ? "rotateY(180deg)" : "rotateY(0deg)"
                    }}
                >
                    <Box sx={{
                        position: "relative"
                    }}>
                        <Box sx={{
                            position: "absolute",
                            height: modify ? "150%" : "136%",
                            width: modify ? "125%" : "100%",
                            top: modify ? "-44%" : "-32%",
                            background: `url(${picture}) no-repeat center`,
                            backgroundSize: "contain",
                            transform: modify ? "rotateY(180deg)" : "rotateY(0deg)"
                        }}/>

                    </Box>
                    <Box
                        sx={{
                            transform: modify ? "rotateY(180deg)" : "rotateY(0deg)"
                        }}
                    >
                        <ContactsElementUpperTitle>{upperText}</ContactsElementUpperTitle>
                        <ContactsElementTitle>{name}</ContactsElementTitle>
                        <ContactsElementUnderTitle>{underText}</ContactsElementUnderTitle>
                        {/*<ContactsLink href={`tel:${phone}`} onClick={() => {*/}
                        {/*    gaEventTracker("phone click", "clicked to phone");*/}
                        {/*}}>*/}
                        {/*    <LocalPhoneOutlinedIcon htmlColor={"#FFFFFF"}/>*/}
                        {/*    <ContactsElementUnderTitle>{phone}</ContactsElementUnderTitle>*/}
                        {/*</ContactsLink>*/}
                        <ContactsLink href={`mailto:${email}`} onClick={() => {
                            gaEventTracker("email click", "clicked to email");
                        }}>
                            <EmailOutlinedIcon htmlColor={"#FFFFFF"}/>
                            <ContactsElementUnderTitle>{email}</ContactsElementUnderTitle>
                        </ContactsLink>
                        {calendarLink &&  <ContactsLink href={`${calendarLink}`} target={"_blank"} onClick={() => {
                            gaEventTracker("calendarLink", "clicked to calendar");
                        }}>
                            <VideocamOutlinedIcon htmlColor={"#FFFFFF"}/>
                            <ContactsElementUnderTitle>{"Book an online Meeting"}</ContactsElementUnderTitle>
                        </ContactsLink>}
                    </Box>
                </ContactsElement>
            </ContactsElementPadding>
        );
    };
    
    return (
        <ContactsContainer>
            <ContactsTitle>Want to know more? — Contact Us</ContactsTitle>
            <ContactsElementsContainer>
                {renderContactEl(contactPicture1, "Henri Manner", "EMEA-region", "CCO, Pentonet", "+358\n50\n2349", "Henri@pentonet.com", false, "https://calendar.google.com/calendar/u/0/selfsched?sstoken=UUtIMGdKc0JIbmJffGRlZmF1bHR8OGQyZWYzMTBiZWIzZDM4OWY3NWJlODExNDYzMWM2YTk" )}
                {window.innerWidth > 768 && <i/>}
                {renderContactEl(contactPicture2, "Trevor Whitworth", "APAC-region", "CD, Pentonet", "+63\n917\n129\n5144" , "Trevor@pentonet.com", true, "https://calendar.google.com/calendar/u/0/selfsched?sstoken=UU5Id0M3NUY0dHRzfGRlZmF1bHR8YmQ2Y2FjZjI0MTE5ZjYwZjJjMTA0MmYwZGYxNTVjNzg")}
            </ContactsElementsContainer>
        </ContactsContainer>
    );
};

export default Contacts;