import React from "react";
import {Box, IconButton, styled} from "@mui/material";
import {NavLink} from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

const FooterContainer = styled("footer")({
    padding: "20px",
    width: "100%",
    boxSizing: "border-box",
    background: "#000000",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
});

const FooterLink = styled(NavLink)({
    color: "#FFFFFF",
    fontSize: ".75rem",
    lineHeight: "20px",
    fontWeight: 700,
    textDecoration: "none",
});

const FooterContainerEl = styled(Box)({
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "&:last-of-type":{
        justifyContent: "end"
    }
});

const Footer = ():JSX.Element => {
    return (
        <FooterContainer>
            <FooterContainerEl>
                <FooterLink to={"/legal/terms"}>Terms & Conditions</FooterLink>
                <FooterLink to={"/legal/privacy-policy"}>Privacy policy</FooterLink>
                <FooterLink to={"/legal/cookies"}>Cookie Policy</FooterLink>
            </FooterContainerEl>
            <FooterContainerEl>
                <a target="_blank" href="https://fi.linkedin.com/company/pentonet" rel="noreferrer">
                    <IconButton>
                        <LinkedInIcon htmlColor={"#FFFFFF"}/>
                    </IconButton>
                </a>
                <a target="_blank" href="mailto:sales@pentonet.com" rel="noreferrer">
                    <IconButton>
                        <EmailIcon htmlColor={"#FFFFFF"}/>
                    </IconButton>
                </a>
            </FooterContainerEl>
        </FooterContainer>
    );    
};

export default Footer;