import React from "react";
import {Box, styled, Typography} from "@mui/material";

const PartnershipTitle = styled(Typography)({
    textTransform: "uppercase",
    fontSize: "3.75rem",
    fontWeight: 700,
    textAlign: "center",
    color: "#FFFFFF",
    "@media(max-width: 768px)":{
        fontSize: "20px",
    },
});

const PartnershipText = styled(Box)({
    padding: "20px 30px",
    boxSizing: "border-box",
    "& p":{
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: 400
    },
    "& li":{
        fontSize: "20px",
        color: "#FFFFFF",
    }
});

const PartnershipInfo = ():JSX.Element => {
    return (
        <>
            <PartnershipTitle>
                Partnerships
            </PartnershipTitle>
            <PartnershipText>
                <p> PENTONET values our sales channel Partners.
                    Our system is straightforward, you enjoy benefits that increase in value as you move up the tiers.
                    To achieve the next tier you need to commit to a fixed amount
                    of purchased FleetConnex-systems on yearly basis by signing
                    a frame agreement with us:</p>
                <ul>
                    <li>50 FleetConnex-systems to reach SILVER LEVEL;</li>
                    <li>100 FleetConnex-systems to reach GOLD LEVEL;</li>
                    <li>150 FleetConnex-systems to reach PLATINUM LEVEL.</li>
                </ul>
                <ul>
                    <li>Once you achieve the next tier, you enjoy your membership privileges for 14 months,
                        even if your ordered amount is  10% below the agreed amount by the end of the 12-months period.
                        The purpose of the period to work together to retain the tier status in your annual review.</li>
                </ul>
            </PartnershipText>
        </>
    );
};

export default PartnershipInfo;