import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
const TRACKING_ID_1 = "G-WY9RS9JGPT";
//const TRACKING_ID_2 = "G-FD602FQW5F";
ReactGA.initialize(TRACKING_ID_1);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
