import React from "react";
import {styled, Typography} from "@mui/material";
import shopBackgroundTemp from "../../assets/shopBackgroundTemp.png";
import shopBackgroundTempMobile from "../../assets/shopBackgroundTempMobile.png";
import shopMapBackground from "../../assets/shopMapBackground.png";

const ShopContainer = styled("main")({
    height: "calc(100vh - 180px)",
    marginTop: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0 0 50%",
    boxSizing: "border-box",
    textAlign: "center",
    fontWeight: 700,
    color: "#FFFFFF",
    background: `url(${shopBackgroundTemp}) no-repeat center, url(${shopMapBackground}) no-repeat center`,
    backgroundSize: "cover",
    textShadow: "#000000 1px 0 10px",
    "& h3":{
        padding: "5%",
        background: "rgba(33,33,33,.5)",
    },
    "@media(max-width: 768px)":{
        background: `url(${shopBackgroundTempMobile}) no-repeat 50% 15%, url(${shopMapBackground}) no-repeat center`,
        backgroundSize: "200%, cover",
        padding: "5% 5% 50%",
        alignItems: "end",
        "& h3":{
            fontSize: "2em",
        }
    },
    "@media(max-width: 400px)":{
        "& h3":{
            fontSize: "1.2em",
        }
    },
    "@media(max-height: 768px)":{
        "& h3":{
            marginTop: "auto",
            fontSize: "1.2em",
        }
    }
});

const Shop = ():JSX.Element => {
    return <ShopContainer>
        <Typography variant={"h3"}>
            Hey there!<br/>
            <br/>
            Great to see you&apos;re keen!<br/>
            We are too but good things take time. Come back soon and we&apos;ll be ready for you!
        </Typography>
    </ShopContainer>;
};

export default Shop;