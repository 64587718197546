import React from "react";
import {styled} from "@mui/material";
import SailorTalkStatement from "./SailorTalkStatement";
import SailorTalkElementFirst from "./SailorTalkElementFirst";
import SailorTalkElementSecond from "./SailorTalkElementSecond";
import SailorTalkElementThird from "./SailorTalkElementThird";
import Contacts from "../Main/Contacts";

const SailorTalkContainer = styled("main")({
    padding:0,
    marginBottom: "40px",
});

const SailorTalk = ():JSX.Element => {
    return <SailorTalkContainer>
        <SailorTalkStatement/>
        <SailorTalkElementFirst/>
        <SailorTalkElementSecond/>
        <SailorTalkElementThird/>
        <Contacts/>
    </SailorTalkContainer>;
};

export default SailorTalk;