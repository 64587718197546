import React from "react";
import {Button, styled, Box} from "@mui/material";
import fleetConnexMainVideoPoster from "../../assets/fleetConnexMainVideoPoster.png";
import {StatementText, StatementTitle} from "../Main/Statement";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import {useNavigate} from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import {StarlinkCompanionIcon} from "../../assets/StarlinkCompanionIcon";

const FleetConnexStatementContainer = styled("section")({
    position: "relative",
    width: "100%",
    height: "fit-content",
    "@media(max-width: 768px)":{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        "& video":{
            height: "100%",
            width: "auto",
            marginLeft: "-130%"
        }
    },
    "@media(max-height: 400px)":{
        "& video": {
            marginLeft: "0"
        }
    }
});

export const FleetConnexStatementButtonContainer = styled(Box)({
    position: "absolute",
    bottom: "40px",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    "@media(max-width: 768px)":{
        flexDirection : "column",
        alignItems: "center"
    }
});

export const FleetConnexStatementLink = styled(Button)({
    color: "#FFFFFF",
    textShadow: "#000000 1px 0 10px",
    fontSize: "1.1rem",
    background: "rgba(33,33,33,.6)",
    transition: "background .3s ease",
    "&:hover":{
        background: "rgba(33,33,33,.8)",
    },
    "@media(max-width: 768px)":{
        fontSize: "0.75rem",
    }
});

const FleetConnexStatement = ():JSX.Element => {

    const navigate = useNavigate();

    return (
        <FleetConnexStatementContainer>
            <video width={"100%"} autoPlay muted loop preload="auto" playsInline poster={fleetConnexMainVideoPoster}>
                <source src={require("../../assets/fleetConnexMainVideo.mp4")}/>
            </video>
            <StatementText>
                <StatementTitle>
                    Global Maritime Connectivity
                </StatementTitle>
            </StatementText>
            <FleetConnexStatementButtonContainer>
                <FleetConnexStatementLink onClick={() => navigate("/connectivityMap")}>
                    <PublicIcon htmlColor={"#FFFFFF"} sx={{
                        marginRight: "10px",
                    }} />
                View availability 
                    <KeyboardArrowRightOutlinedIcon htmlColor={"#FFFFFF"}/>
                </FleetConnexStatementLink>
                <FleetConnexStatementLink onClick={() => navigate("/starlinkCompanion")}>
                    <Box
                        sx={{
                            width: "32px",
                            "& *":{
                                transform: "scale(1.2)"
                            }
                        }}
                    >
                        <StarlinkCompanionIcon/>
                    </Box>
                    Starlink Companion
                    <KeyboardArrowRightOutlinedIcon htmlColor={"#FFFFFF"}/>
                </FleetConnexStatementLink>
            </FleetConnexStatementButtonContainer>
        </FleetConnexStatementContainer>
    );
};

export default FleetConnexStatement;