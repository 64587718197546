import React from "react";
import {Box, styled, Typography} from "@mui/material";
import OfficeElement from "./OfficeElement";
import Connect1 from "./Connect1";
import Connect2 from "./Connect2";
import DeviceColumn from "./DeviceColumn";
import HubColumn from "./HubColumn";
import Options from "./Options";
import InternetOptions from "./InternetOptions";

const StarlinkCompanionElementSecondContainer = styled(Box)({
    padding: "0 5%",
    height: "40vh",
    display: "grid",
    marginTop: "20px",
    minHeight: "500px",
    gridTemplateColumns: "4fr 2fr 3fr 6fr 3fr 1fr 5fr",
    "& li":{
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: 600,
    },
    "& p":{
        margin: "2px 0",
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: 600,
    },
    "@media(max-width: 768px)":{
        height: "auto",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 0.1fr 1fr 1fr 0.1fr 0.1fr 1fr",
    }
});

const StarlinkCompanionElementSecondTitle = styled(Typography)({
    color: "#FFFFFF",
    fontSize: "22px",
    fontWeight: 600,
    textAlign: "center",
    marginTop: "20px",
    textTransform: "uppercase"
});

const StarlinkCompanionElementSecond = ():JSX.Element => {
    
    return (
        <Box>
            <StarlinkCompanionElementSecondTitle>
                All-New Technology: Unparalleled Speed + Beyond Simple Failover
            </StarlinkCompanionElementSecondTitle>
            <StarlinkCompanionElementSecondContainer>
                <OfficeElement/>
                <Connect1/>
                <DeviceColumn/>
                <Options/>
                <HubColumn/>
                <Connect2/>
                <InternetOptions/>
            </StarlinkCompanionElementSecondContainer>
        </Box>
    );
};

export default StarlinkCompanionElementSecond;