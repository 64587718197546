import React from "react";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import StorageIcon from "@mui/icons-material/Storage";
import PublicIcon from "@mui/icons-material/Public";
import {Box, styled} from "@mui/material";
import {AwsIcon} from "../../../assets/AwsIcon";
import {OutlookIcon} from "../../../assets/OutlookIcon";
import {GsuiteIcon} from "../../../assets/GsuiteIcon";
import {MicrosoftOfficeIcon} from "../../../assets/MicrosoftOfficeIcon";

const StarlinkCompanionElementSecondInternetOptionsElsContainer = styled(Box)({
    position: "relative",
    padding: "0 15%",
    display: "grid",
    gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
    rowGap: "10px",
});

const StarlinkCompanionElementSecondInternetOptionsEl = styled(Box)({
    fontSize: "16px",
    fontWeight: 600,
    border: "1px solid #FFFFFF",
    borderRadius: "25px",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    display: "flex",
    gap: "10px",
    padding: "0 10px",
    boxSizing: "border-box",
});

const StarlinkCompanionElementSecondInternetOptionsElUnderText = styled(Box)({
    fontSize: "24px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    color: "#FFFFFF",
});


const InternetOptions = ():JSX.Element => {
    return (
        <StarlinkCompanionElementSecondInternetOptionsElsContainer>
            <StarlinkCompanionElementSecondInternetOptionsEl>
                Internet:
                <GoogleIcon/>
                <InstagramIcon/>
                <FacebookIcon/>
                <OutlookIcon/>
            </StarlinkCompanionElementSecondInternetOptionsEl>
            <StarlinkCompanionElementSecondInternetOptionsEl>
                Cloud&nbsp;Providers:
                <CloudDownloadIcon/>
                <AwsIcon/>
            </StarlinkCompanionElementSecondInternetOptionsEl>
            <StarlinkCompanionElementSecondInternetOptionsEl>
                Mobile&nbsp;Offices:
                <GsuiteIcon/>
                <MicrosoftOfficeIcon/>
            </StarlinkCompanionElementSecondInternetOptionsEl>
            <StarlinkCompanionElementSecondInternetOptionsEl>
                Data&nbsp;Center:
                <StorageIcon/>
                <PublicIcon/>
            </StarlinkCompanionElementSecondInternetOptionsEl>
            <StarlinkCompanionElementSecondInternetOptionsElUnderText>Ship Owner Services</StarlinkCompanionElementSecondInternetOptionsElUnderText>
        </StarlinkCompanionElementSecondInternetOptionsElsContainer>
    );
};

export default InternetOptions;