import React from "react";
import {Box, styled} from "@mui/material";
import {FleetConnexElementText,
    FleetConnexElementTextTitle} from "../FleetConnex/FleetConnexElementFirst";

const SailorTalkElementThirdContainer = styled(Box)({
    display: "block",
    height: "auto",
});

const ShipTalkElementThird = ():JSX.Element => {
    return (
        <SailorTalkElementThirdContainer>
            <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>
               SATELLITE CALLING
                </FleetConnexElementTextTitle>
                <p>ShipTalk Satellite Calling is an extension of the core ShipTalk product that allows shore-based users
                    to call certain Satellite Networks at very advantageous prices which
                    are typically half the cost of calling these terminals using local Telco’s.</p>
                <p>In an office environment, it’s simply a question of programming
                    a SIP-enabled PBX so that user-dialled calls to a satellite network are routed to our ShipTalk
                    core from where we pass them to the required destination. We will then bill usage at the
                    end of the calendar month in the usual fashion.</p>
                <p>At this time, we can offer very favourable costs for calling Inmarsat Fleet Broadband (FBB)
                    numbers and are working to add the Thuraya and Iridium networks in the coming months.
                    Calls to both the latter networks are currently available but not at such favourable rates as for FBB.</p>
            </FleetConnexElementText>
        </SailorTalkElementThirdContainer>
    );
};

export default ShipTalkElementThird;