export const dataFormatter = (data: Date | string | number):string => {
    if(Math.abs(new Date(data).getTime() - new Date().getTime()) < 86400){
        return new Date(data).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }else{
        return new Date(data).toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }
};