import React from "react";
import {Box, styled} from "@mui/material";

const StarlinkCompanionElementSecondOptionsContainer = styled(Box)({
    position: "relative",
    display: "grid",
    gridTemplateRows: "1.5fr 6fr",
    rowGap: "10px",
    boxSizing: "border-box",
    textAlign: "center"
});

const StarlinkCompanionElementSecondOptionsElsContainer = styled(Box)({
    position: "relative",
    padding: "0 15%",
    display: "grid",
    gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr",
    rowGap: "10px",
});

const StarlinkCompanionElementSecondOptionsEl = styled(Box)({
    fontSize: "16px",
    fontWeight: 600,
    border: "1px solid #FFFFFF",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:nth-of-type(1)":{
        color: "#FFFFFF",
        background: "#e9005c",
        "&:before":{
            content: "' '",
            position: "absolute",
            left: 0,
            top: "7%",
            height: "40%",
            width: "15%",
            borderRadius: "100% 0 0 0",
            borderLeft: "1px dashed #e9005c",
            borderTop: "1px dashed #e9005c",
        },
        "&:after":{
            content: "' '",
            position: "absolute",
            right: 0,
            top: "7%",
            height: "40%",
            width: "15%",
            borderRadius: "0 100% 0 0",
            borderRight: "1px dashed #e9005c",
            borderTop: "1px dashed #e9005c",
        }
    },
    "&:nth-of-type(2)":{
        background: "#ceeffd",
        "&:before":{
            content: "' '",
            position: "absolute",
            left: 0,
            top: "25%",
            height: "25%",
            width: "15%",
            borderRadius: "100% 0 0 0",
            borderLeft: "1px dashed #cdcdcd",
            borderTop: "1px dashed #cdcdcd",
        },
        "&:after":{
            content: "' '",
            position: "absolute",
            right: 0,
            top: "25%",
            height: "25%",
            width: "15%",
            borderRadius: "0 100% 0 0",
            borderRight: "1px dashed #cdcdcd",
            borderTop: "1px dashed #cdcdcd",
        }
    },
    "&:nth-of-type(3)":{
        background: "#e6e6e6",
        "&:before":{
            content: "' '",
            position: "absolute",
            left: 0,
            top: "42%",
            height: "9%",
            width: "15%",
            borderRadius: "100% 0 0 0",
            borderLeft: "1px dashed #e6e6e6",
            borderTop: "1px dashed #e6e6e6",
        },
        "&:after":{
            content: "' '",
            position: "absolute",
            right: 0,
            top: "42%",
            height: "9%",
            width: "15%",
            borderRadius: "0 100% 0 0",
            borderRight: "1px dashed #e6e6e6",
            borderTop: "1px dashed #e6e6e6",
        }
    },
    "&:nth-of-type(4)":{
        background: "#e6e6e6",
        "&:before":{
            content: "' '",
            position: "absolute",
            left: 0,
            top: "50%",
            height: "9%",
            width: "15%",
            borderRadius: "0 0 0 100%",
            borderLeft: "1px dashed #e6e6e6",
            borderBottom: "1px dashed #e6e6e6",
        },
        "&:after":{
            content: "' '",
            position: "absolute",
            right: 0,
            top: "50%",
            height: "9%",
            width: "15%",
            borderRadius: "0 0 100% 0",
            borderRight: "1px dashed #e6e6e6",
            borderBottom: "1px dashed #e6e6e6",
        }
    },
    "&:nth-of-type(5)":{
        background: "#f0cceb",
        "&:before":{
            content: "' '",
            position: "absolute",
            left: 0,
            top: "50%",
            height: "25%",
            width: "15%",
            borderRadius: "0 0 0 100%",
            borderLeft: "1px dashed #c9c9c9",
            borderBottom: "1px dashed #c9c9c9",
        },
        "&:after":{
            content: "' '",
            position: "absolute",
            right: 0,
            top: "50%",
            height: "25%",
            width: "15%",
            borderRadius: "0 0 100% 0",
            borderRight: "1px dashed #c9c9c9",
            borderBottom: "1px dashed #c9c9c9",
        }
    },
    "&:nth-of-type(6)":{
        background: "#c9e7aa",
        "&:before":{
            content: "' '",
            position: "absolute",
            left: 0,
            top: "50%",
            height: "40%",
            width: "15%",
            borderRadius: "0 0 0 100%",
            borderLeft: "1px dashed #e7e9da",
            borderBottom: "1px dashed #e7e9da",
        },
        "&:after":{
            content: "' '",
            position: "absolute",
            right: 0,
            top: "50%",
            height: "40%",
            width: "15%",
            borderRadius: "0 0 100% 0",
            borderRight: "1px dashed #e7e9da",
            borderBottom: "1px dashed #e7e9da",
        }
    }
});


const StarlinkCompanionElementSecondOptionsNumbers = styled(Box)({
    position: "absolute",
    padding: "10px 5px",
    top: "28%",
    left: "3%",
    height: "62%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "25px",
    background: "#e6e6e6",
    "& span":{
        fontSize: "12px",
        fontWeight: 600,
        color: "#000",
    }
});

const Options = ():JSX.Element => {
    return (
        <StarlinkCompanionElementSecondOptionsContainer>
            <ol start={3}>
                <li>
                    SDWAN Optimizes IP
                    flaws for latent/lossy
                    wireless links
                </li>
            </ol>
            <StarlinkCompanionElementSecondOptionsElsContainer>
                <StarlinkCompanionElementSecondOptionsEl>
                    4G WAN1
                </StarlinkCompanionElementSecondOptionsEl>
                <StarlinkCompanionElementSecondOptionsEl>
                    4G WAN2
                </StarlinkCompanionElementSecondOptionsEl>
                <StarlinkCompanionElementSecondOptionsEl>
                    Starlink WAN3
                </StarlinkCompanionElementSecondOptionsEl>
                <StarlinkCompanionElementSecondOptionsEl>
                    Starlink WAN4
                </StarlinkCompanionElementSecondOptionsEl>
                <StarlinkCompanionElementSecondOptionsEl>
                    VSAT WAN5
                </StarlinkCompanionElementSecondOptionsEl>
                <StarlinkCompanionElementSecondOptionsEl>
                    DSL WAN6
                </StarlinkCompanionElementSecondOptionsEl>
            </StarlinkCompanionElementSecondOptionsElsContainer>
            <StarlinkCompanionElementSecondOptionsNumbers>
                <span>20%</span>
                <span>10%</span>
                <span>30%</span>
                <span>10%</span>
                <span>5%</span>
                <span>25%</span>
            </StarlinkCompanionElementSecondOptionsNumbers>
        </StarlinkCompanionElementSecondOptionsContainer>
    );
};

export default Options;