import React from "react";
import {styled} from "@mui/material";
import PartnershipInfo from "./PartnershipInfo";
import PartnershipTypes from "./PartnershipTypes";
import Contacts from "../Main/Contacts";

const PartnershipContainer = styled("main")({
    marginTop: "120px",
    padding:0,
    marginBottom: "40px",
});

const Partnership = ():JSX.Element => {
    return <PartnershipContainer>
        <PartnershipInfo/>
        <PartnershipTypes/>
        <Contacts/>
    </PartnershipContainer>;
};

export default Partnership;