import React from "react";
import {IconButton, Tooltip, Typography} from "@mui/material";

interface HeaderTooltipInterface {
    text: string;
    children: React.ReactNode
}

const HeaderTooltip = ({text, children}: HeaderTooltipInterface):JSX.Element => {
    return (
        <Tooltip
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip":{
                        marginTop: "0px !important",
                        background: "transparent",
                    },
                }
            }}
            title={<Typography sx={{
                padding: 0,
                fontSize: "18px",
                fontWeight: "500"
            }}>{text}</Typography>}>
            <IconButton>
                {children}
            </IconButton>
        </Tooltip>
    );
};

export default HeaderTooltip;