import React from "react";
import partnershipVideo1Poster from "../../assets/partnershipVideo1Poster.png";
import fleetConnex3 from "../../assets/fleetConnex3.png";
import partnershipVideo2Poster from "../../assets/partnershipVideo2Poster.png";
import partnershipVideo3Poster from "../../assets/partnershipVideo3Poster.png";
import {Box, styled, Typography} from "@mui/material";

const PartnershipTypesContainer = styled(Box)({
    height: "700px",
    width: "100%",
    marginTop: "40px",
    padding: "0 10%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "20px",
    boxSizing: "border-box",
    "@media(max-width: 768px)":{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        rowGap: "20px",
    },
    "@media(max-width: 1200px)":{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        rowGap: "20px",
    }
});

const PartnershipTypesElement = styled(Box)({
    position: "relative",
    overflow: "hidden",
    "& video":{
        height: "100%",
        width: "auto",
        marginLeft: "-90%",
    },
    "@media(max-width: 768px)":{
        "& video": {
            height: "auto",
            width: "100%",
            marginLeft: "0",
        }
    },
    "@media(max-width: 1200px)":{
        "& video": {
            height: "auto",
            width: "100%",
            marginLeft: "0",
        }
    }
});

const PartnershipTypesElementTitle = styled(Box)({
    position: "absolute",
    height: "100%",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const PartnershipTypesElementTitleText = styled(Typography)({
    fontSize: "40px",
    fontWeight: 700,
    padding: "15px 30px",
    textTransform: "uppercase",
    width: "55%",
    textAlign: "center",
    "@media(max-width: 768px)":{
        fontSize: "20px",
    }
});

const PartnershipTypesElementBottom = styled(Box)({
    position: "absolute",
    bottom: 0,
    left: "10px",
    height: "20%",
    "& img":{
        height: "100%",
        marginLeft: "-20px",
    },
    "& span":{
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: 400,
        position: "absolute",
        width: "max-content",
        bottom: "10px",
    },
    "@media(max-width: 768px)":{
        height: "40%",
    }
});

const PartnershipTypes = ():JSX.Element => {
    return (
        <PartnershipTypesContainer>
            <PartnershipTypesElement>
                <video height={"100%"} autoPlay muted loop preload="auto" playsInline poster={partnershipVideo1Poster}>
                    <source src={require("../../assets/partnershipVideo1.mp4")}/>
                </video>
                <PartnershipTypesElementTitle>
                    <PartnershipTypesElementTitleText
                        sx={{
                            background: "#D9D9D9"
                        }}
                    >
                        Silver
                    </PartnershipTypesElementTitleText>
                </PartnershipTypesElementTitle>
                <PartnershipTypesElementBottom>
                    <img alt={"fleetConnexImage"} src={fleetConnex3}/>
                    <span>
                        50 FleetConnex-systems<br/>
                        to reach SILVER LEVEL
                    </span>
                </PartnershipTypesElementBottom>
            </PartnershipTypesElement>
            <PartnershipTypesElement>
                <video width={"100%"} autoPlay muted loop preload="auto" playsInline poster={partnershipVideo2Poster}>
                    <source src={require("../../assets/partnershipVideo2.mp4")}/>
                </video>
                <PartnershipTypesElementTitle>
                    <PartnershipTypesElementTitleText
                        sx={{
                            background: "#E1C340"
                        }}
                    >
                        Gold
                    </PartnershipTypesElementTitleText>
                    <PartnershipTypesElementBottom>
                        <img alt={"fleetConnexImage"} src={fleetConnex3}/>
                        <img alt={"fleetConnexImage"} src={fleetConnex3}/>
                        <span>
                        100 FleetConnex-systems<br/>
                        to reach GOLD LEVEL
                        </span>
                    </PartnershipTypesElementBottom>
                </PartnershipTypesElementTitle>
            </PartnershipTypesElement>
            <PartnershipTypesElement>
                <video width={"100%"} autoPlay muted loop preload="auto" playsInline poster={partnershipVideo3Poster}>
                    <source src={require("../../assets/partnershipVideo3.mp4")}/>
                </video>
                <PartnershipTypesElementTitle>
                    <PartnershipTypesElementTitleText
                        sx={{
                            background: "#000000",
                            color: "#FFFFFF"
                        }}
                    >
                        Platinum
                    </PartnershipTypesElementTitleText>
                </PartnershipTypesElementTitle>
                <PartnershipTypesElementBottom>
                    <picture>
                        <source type={"image/webp"} sizes="(min-width: 366px) 366px, 100vw" srcSet={fleetConnex3}/>
                        <img alt={"fleetConnexImage"} role={"presentation"} aria-hidden={true} src={fleetConnex3}/>
                    </picture>
                    <img alt={"fleetConnexImage"} src={fleetConnex3}/>
                    <img alt={"fleetConnexImage"} src={fleetConnex3}/>
                    <span>
                        150 FleetConnex-systems<br/>
                        to reach PLATINUM LEVEL
                    </span>
                </PartnershipTypesElementBottom>
            </PartnershipTypesElement>
        </PartnershipTypesContainer>
    );
};

export default PartnershipTypes;