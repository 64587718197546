import React from "react";
import {Box, styled} from "@mui/material";
import starlinkCompanion2 from "../../../assets/starlinkCompanion2.png";

export const StarlinkCompanionElementSecondDeviceColumnContainer = styled("ol")({
    display: "grid",
    gridTemplateRows: "1fr 3fr 1fr",
});

const DeviceColumn = ():JSX.Element => {
    return (
        <StarlinkCompanionElementSecondDeviceColumnContainer>
            <li>
                SDWAN bonds 6 WAN sources
                with a Tunnel
                from Remote to Konnect Hub
            </li>
            <Box sx={{
                background:  `url(${starlinkCompanion2}) no-repeat center`,
                backgroundSize: "contain",
            }}/>
            <li>
                SDWAN monitors
                speed, congestion
                and adjusts assignments
            </li>
        </StarlinkCompanionElementSecondDeviceColumnContainer>
    );
};

export default DeviceColumn;