import React from "react";
import {FleetConnexElementText, FleetConnexElementTextTitle} from "../FleetConnex/FleetConnexElementFirst";
import {styled} from "@mui/material";
import {FleetConnexElementSecondContainer} from "../FleetConnex/FleetConnexElementSecond";

const ShipTalkElementSecondText = styled(FleetConnexElementText)({
    "& li":{
        fontWeight: 400,
        textTransform: "none"
    }
});

const ShipTalkElementSecond = ():JSX.Element => {
    return (
        <FleetConnexElementSecondContainer
            sx={{
                height: "auto"
            }}
        >
            <FleetConnexElementText>
                <FleetConnexElementTextTitle variant={"h6"}>
                    SHIPTALK
                </FleetConnexElementTextTitle>
                <p>ShipTalk by Pentonet is a specifically designed Maritime VoIP service for support of ship communications,
                    crew communications and business activity using the ship’s satcomms system as the means of transmission.</p>
                <p>ShipTalk offers incoming numbers for over 50 countries facilitating cheap calls from the “Office”
                    to the ship when the ship’s DID (Direct Inward Dialling) number is from the same Country as the Office is located.</p>
                <p>It is a simple to deploy, simple to use,
                    yet High Quality post & prepaid VoIP Service.</p>
            </FleetConnexElementText>
            <ShipTalkElementSecondText>
                <FleetConnexElementTextTitle variant={"h6"}>
                VoIP SERVICE
                </FleetConnexElementTextTitle>
                <p>There is no requirement for any expensive or complicated equipment
                    all the ship requires is a standard Voip phone,
                    or computer or even personal smartphone.</p>
                <p>Dial the required number,
                    input the PIN (for pre-pay Users) and enjoy the benefits of ShipTalk:</p>
                <ul>
                    <li>Low rates and call forwarding</li>
                    <li>Capping to prevent any shocks with bills</li>
                    <li>Line Sharing to multiple handsets/devices</li>
                    <li>Postpaid service which can be capped to prevent over-use</li>
                    <li>Prepaid calling cards with various dollar values for calling cards</li>
                </ul>
            </ShipTalkElementSecondText>
        </FleetConnexElementSecondContainer>
    );
};

export default ShipTalkElementSecond;