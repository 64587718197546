import React from "react";
import {Box, styled, Typography} from "@mui/material";
import fleetConnexImg from "../../assets/fleetConnexImg.jpg";
import starlinkCompanionImg from "../../assets/starlinkCompanionImg.jpg";
import sailorTalk from "../../assets/sailorTalk.jpg";
import shipTalk from "../../assets/shipTalk.jpg";
import {NavLink} from "react-router-dom";

const ProductsContainer = styled("section")({
    height: "1000px",
    width: "100%",
    marginTop: "40px",
    padding: "0 5%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    columnGap: "20px",
    rowGap: "20px",
    boxSizing: "border-box",
    "@media(max-width: 768px)":{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr 1fr 1fr",
    },
    "@media(max-width: 1200px)":{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr 1fr 1fr",
    }
});

const ProductsElement = styled(NavLink)({
    position: "relative",
    overflow: "hidden",
});

const ProductUnderText = styled("p")({
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 400,
    textShadow: "#000000 1px 0 1px",
    "@media(max-width: 768px)":{
        fontSize: "12px",
    }
});

const ProductsElementTitle = styled(Typography)({
    fontSize: "40px",
    fontWeight: 700,
    color: "#FFFFFF",
    position: "absolute",
    top: "20px",
    left: "20px",
    textShadow: "#000000 1px 0 10px",
    textTransform: "uppercase",
    "@media(max-width: 768px)":{
        fontSize: "20px",
    }
});

const ProductsElementImage = styled(Box)({
    height: "100%",
    width: "100%",
    filter: "brightness(75%)",
    transition: "transform .3s ease-in,filter .3s ease-in",
    "&:hover":{
        transform: "scale(1.1)",
        filter: "brightness(100%)",
    },
    "@media(max-width: 1200px)":{
        backgroundPosition: 0
    }
});

const Products = ():JSX.Element => {
    return (
        <ProductsContainer>
            <ProductsElement
                to={"/starlinkCompanion"}
            >
                <ProductsElementImage
                    sx={{
                        background: `url(${starlinkCompanionImg}) no-repeat center 0`,
                        backgroundSize: "cover",
                    }}
                />
                <ProductsElementTitle variant={"h6"} >
                    Starlink Companion
                    <ProductUnderText>
                        LTE+LEO COMPANION, THE MOST COST EFFECTIVE WAY TO MANAGE A MODERN MULTI-BANDWIDTH CONNECTED FLEET OF VESSELS OPERATING IN INTERNATIONAL WATERS. CLICK HERE TO UNDERSTAND WHY 1+1=4.
                    </ProductUnderText>
                </ProductsElementTitle>

            </ProductsElement>
            <ProductsElement
                to={"/fleetConnex"}
            >
                <ProductsElementImage
                    sx={{
                        background: `url(${fleetConnexImg}) no-repeat center`,
                        backgroundSize: "cover",
                    }}
                />
                <ProductsElementTitle variant={"h6"} >
                    FleetConnex
                    <ProductUnderText>
                        Our near to shore Global LTE solution offering up to 600MBps is
                        ideal for connecting your vessels to high speed, low cost data packages.
                    </ProductUnderText>
                </ProductsElementTitle>

            </ProductsElement>
            <ProductsElement
                to={"/sailorTalk"}
            >
                <ProductsElementImage
                    sx={{
                        background: `url(${sailorTalk}) no-repeat center 100%`,
                        backgroundSize: "cover",
                    }}
                />
                <ProductsElementTitle variant={"h6"} >
                    SailorTalk
                    <ProductUnderText>
                        Pentonet’s Global service for crew and passengers at sea and on land for data up
                        to 21/5.75 Mbps, voice and SMS at truly affordable rates. The Global SIM!
                    </ProductUnderText>
                </ProductsElementTitle>
            </ProductsElement>
            <ProductsElement
                to={"/shipTalk"}
            >
                <ProductsElementImage
                    sx={{
                        background: `url(${shipTalk}) no-repeat center`,
                        backgroundSize: "cover",
                    }}
                />
                <ProductsElementTitle variant={"h6"} >
                    ShipTalk
                    <ProductUnderText>
                        ShipTalk is our VoIP service offering prepaid and postpaid services for vessels.
                        Providing a flexible range of international dial in numbers ShipTalk is simple, effective.
                    </ProductUnderText>
                </ProductsElementTitle>
            </ProductsElement>
        </ProductsContainer>
    );
};

export default Products;