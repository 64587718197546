import React from "react";
import {styled} from "@mui/material";
import FleetConnexStatement from "./FleetConnexStatement";
import FleetConnexElementFirst from "./FleetConnexElementFirst";
import FleetConnexElementSecond from "./FleetConnexElementSecond";
import FleetConnexElementThird from "./FleetConnexElementThird";
import Contacts from "../Main/Contacts";

const FleetConnexContainer = styled("main")({
    padding:0,
    marginBottom: "40px",
});

const FleetConnex = ():JSX.Element => {
    return <FleetConnexContainer>
        <FleetConnexStatement/>
        <FleetConnexElementFirst/>
        <FleetConnexElementSecond/>
        <FleetConnexElementThird/>
        <Contacts/>
    </FleetConnexContainer>;
};

export default FleetConnex;